import React, { useEffect, useMemo, useState } from "react";
import { Chip, Button, Alert } from "../../../../../../styleguide";
import {  EditFilled, InfoCircleFilled } from "@ant-design/icons"
import {  GPP_MSPS_TOOLTIP, FRAMEWORK_TERRITORIES_TOOLTIP, renderInfoTip, STATE_SPECIFIC_STRING_STATE_CODES } from "../../../helper";
import { GEO_LIST_TREE_STRUCTURE, LEGACY_EU_COUNTRY_CODES } from "../../../../../../constants";
import _ from "lodash";
import SignatoryIdModal from "./SignatoryIdModal";
import RegionSelector from "../../../../../common/RegionSelector/RegionSelector";
import { Typography } from "antd";

const IndustryFramework = (props) => {
  
  const [ selectedTerritories, setSelectedTerritories] = useState();
  const [ showRespectGpcAlert, setShowRespectGpcAlert] = useState(false);
  const [ selectedCodes, setSelectedCodes] = useState(null)
  const [ geoScopes, setGeoScope ] = useState({
    appliesGeos : props.usPrivacyRegulation.appliesGeos ?? [],
    exclusionGeos : props.usPrivacyRegulation.exclusionGeos ?? []
  })
  const [ geoScopesError, setGeoScopeError ] = useState(false);
  const [ isSignatoryModalVisible, setIsSignatoryModalVisible ] = useState(false);
  const [ signatoryId, setSignatoryId ] = useState(props.usPrivacyRegulation.signatoryId);
  useEffect(()=>{
    if(props.showError) {
      setGeoScopeError( geoScopes.appliesGeos.length == 0 && geoScopes.exclusionGeos.length == 0 ? "Select at least one Territory" : false)
    } else {
      setGeoScopeError(false);
    }
  },[props.showError])

// selectedCodes for comparision from props.usPrivacyRegulation that are only calculated on component mount and stay constant on every rerender (for DIA-3143)
const PREVIOUSLY_SELECTED_CODES = useMemo(()=>{
  let selectedCodes = [];
    if(props.usPrivacyRegulation.appliesGeos.includes("ALL_EU")){
      selectedCodes = LEGACY_EU_COUNTRY_CODES.filter( code => !props.usPrivacyRegulation.exclusionGeos.includes(code));
    }

    GEO_LIST_TREE_STRUCTURE.forEach((option) => {
      if (props.usPrivacyRegulation.appliesGeos.includes(option.key)) {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedCodes = selectedCodes.concat(filteredChildCodes);
      } else {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => props.usPrivacyRegulation.appliesGeos.includes(code) && !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedCodes = selectedCodes.concat(filteredChildCodes)
      }
    });

    //include canadian provinces
    if(props.usPrivacyRegulation.appliesGeos.includes('ALL_OTHER') && !props.usPrivacyRegulation.exclusionGeos.includes('CAN')){
      selectedCodes = selectedCodes.concat(GEO_LIST_TREE_STRUCTURE.find(geo => geo.key == 'CAN').children.map(c => c.code))
    }
    return selectedCodes;
},[])

  useEffect(()=>{
    props.usPrivacyRegulation.appliesGeos = geoScopes.appliesGeos;
    props.usPrivacyRegulation.exclusionGeos = geoScopes.exclusionGeos;
    props.resetValidationError();
  },[geoScopes])

  useEffect(()=>{
    if(props.summaryPageData){
      props.summaryPageData.geoTerritories = selectedTerritories;
    }
  },[selectedTerritories])

  useEffect(()=>{
    if(selectedCodes && selectedCodes.length && PREVIOUSLY_SELECTED_CODES && PREVIOUSLY_SELECTED_CODES.length && !_.isEqual(selectedCodes, PREVIOUSLY_SELECTED_CODES)){
      if(props.usPrivacyRegulation.categories.filter(c => c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE").some( c => c.respectGPC)){
        setShowRespectGpcAlert(true);
        let updatedCategories = _.clone(props.usPrivacyRegulation.categories);
        updatedCategories = updatedCategories.map(c => {
          if(c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE" && selectedTerritories) {
            c.respectGPC = selectedCodes;
          }
          return c;
        })
        props.usPrivacyRegulation.categories = updatedCategories;
      }
    }
  },[selectedCodes]);

  useEffect(()=>{
    if(props.usPrivacyRegulation.metaData.MspaCoveredTransaction && props.usPrivacyRegulation.metaData.MspaServiceProviderMode) {
      props.usPrivacyRegulation.categories = props.usPrivacyRegulation.categories.filter( cat => !(cat.defaultLegalBasis === 'OPT-OUT' && cat.type !== 'CUSTOM'));
    }
  },[props.usPrivacyRegulation.metaData]);

  const saveSignatoryId = (id, metaData, name,) => {
    setSignatoryId(id);
    props.usPrivacyRegulation.signatoryId = id;
    props.usPrivacyRegulation.signatoryName = name;
    props.usPrivacyRegulation.metaData = metaData;
  }

  const signatoryIdModal =  (
    <SignatoryIdModal
      open={isSignatoryModalVisible}
      signatoryId={signatoryId}
      signatoryName={props.usPrivacyRegulation.signatoryName}
      metaData={props.usPrivacyRegulation.metaData}
      onClose={()=>{
        setIsSignatoryModalVisible(false)
      }}
      handleSave={saveSignatoryId}
    />
  )
  //Commenting out as per https://sourcepoint.atlassian.net/browse/DIA-2945
  // const operationMode = OPERATE_MODE_OPTIONS.find( o => o.value === (props.usPrivacyRegulation.metaData.MspaOptOutOptionMode ? 'MspaOptOutOptionMode' : 'MspaServiceProviderMode'))?.label

  // let regionOptionsWithStateStringStates = GEO_LIST_TREE_STRUCTURE.map(region => {
  //   if(region.key == 'US'){
  //     region.children = region.children.map( state => {
  //       if(STATE_SPECIFIC_STRING_STATE_CODES.includes(state.code)){
  //         state.icon = (<i  className="fas fa-circle" style={{color:'#411F90',width: 10, height: 10,  marginRight: 10}} />)
  //       }
  //       return state;
  //     })
  //   }
  //   return region;
  // })

  return (
    <>
      <div className="step-header">
        <Typography.Title level={4}>Industry Framework Signals</Typography.Title>
      </div>
      <div className="step-breif">
        The US state regulation settings support the IAB Tech Lab's National Multi-State Privacy String. If you are a signatory of the Multi-State Privacy Agreement you can add your ID below.
      </div>
      <div className="step-section">
        <div className="section-header">
          <b>Global Privacy Platform's Multi-State Privacy String (GPP MSPS)</b>
          {renderInfoTip(GPP_MSPS_TOOLTIP)}
        </div>
        <div className="section-content">
          <div className="singatory-id">
            <b>MSPA IDENTIFICATION ID (Optional)</b>
            <div>
              {signatoryId ? <Chip>{signatoryId}</Chip> : null}
              <Button className={`${signatoryId? 'icon' : ''}`} type="primary" onClick={()=>setIsSignatoryModalVisible(true)}>{signatoryId ? <EditFilled/> : 'Add +'}</Button>
            </div>
            { props.usPrivacyRegulation.signatoryName ? <div className="name">Name: &nbsp;<b>{props.usPrivacyRegulation.signatoryName}</b></div> : null}
          </div>
          {/* Commenting out as per https://sourcepoint.atlassian.net/browse/DIA-2945 */}
          {/*<div style={{ marginLeft: "200px" }}>
            <b>FEATUTRES</b>
            <div>
              {GPPMSPS_FEATURES.map((f) => (
                <Chip>
                  {f.title}
                  {renderInfoTip(f.description)}
                </Chip>
              ))}
              {props.usPrivacyRegulation.signatoryId ? (
                <Chip>
                  MSPACoveredTransaction = "{props.usPrivacyRegulation.metaData.MspaCoveredTransaction ? 'Yes' : 'No'}"
                </Chip>
              ): null}
              {props.usPrivacyRegulation.metaData.MspaCoveredTransaction ? (
                <Chip>
                  {operationMode}
                </Chip>
              ): null}
            </div>
          </div> */}
        </div>
      </div>
      <div className="step-header">
      <Typography.Title level={4}>Framework Territories</Typography.Title>
        {renderInfoTip(FRAMEWORK_TERRITORIES_TOOLTIP)}
      </div>
      <div className="step-breif">
        <div>Choose the territories where these privacy regulation settings should apply. For states where a state-string is available, the relevant state-string will be used otherwise the US National String is used.</div>
        {showRespectGpcAlert ? <Alert style={{marginTop: '10px'}} showIcon icon={<InfoCircleFilled className="info-icon" />} message={<div>Modifying the Framework Territories will reset the GPC region selection to <i>All</i> regions. You can update the selection under GPC settings.</div>} type="warning"/> : null}
      </div>
      <div className="step-error">{geoScopesError}</div>
      <div className="step-section">
      <div className={`section-content ${geoScopesError ? 'validation-error' : null}`}>
        <RegionSelector
          isIncludeExcludeStructure={true}
          setGeoScope={setGeoScope}
          appliesGeos={geoScopes.appliesGeos}
          exclusionGeos={geoScopes.exclusionGeos}
          updatedCodes={() => {}}
          codes={[]}
          setSelectedTerritoriesData={setSelectedTerritories}
          addIconsToTheseCodes={STATE_SPECIFIC_STRING_STATE_CODES}
          selectedRegionsLegend={(<div><i  className="fas fa-circle" style={{color:'#411F90',width: 10, height: 10,  marginRight: 10}} /> State Signal Used</div>)}
          setSelectedCodes={setSelectedCodes}
        />
      </div>
      </div>
      {signatoryIdModal}
    </>
  );
}
export default IndustryFramework;
