/* eslint-disable react/prop-types */
import { CheckSquareFilled, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { Select, Tag } from '../../../../styleguide';

const { Option, OptGroup } = Select;

const TagPlaceholder = ({ omitted, showAll, selected }) => {
  const value = omitted
    ?.map((omit) => omit.title)
    ?.filter((title) => title?.toLowerCase() !== 'all')
    ?.join(', ');
  if (showAll && selected.includes('All')) {
    return <Tag label={'All'} value={value} noClass />;
  }
  return <Tag label={`+${omitted.length}...`} value={value} noClass />;
};

const tagRenderer = (props) => {
  return <Tag {...props} />;
};

export const regionsTagRenderer =
  (options, className = '') =>
  (props) => {
    let selectedZone = '';
    if(props.value) {
      [selectedZone] = props.value?.split(':');
    }

    const optionsOfZone = options?.find((option) => option.value === selectedZone);
    return (
      <Tag
        {...props}
        className={`${props.className || ''} ${className}`}
        label={optionsOfZone?.children?.find((region) => region.value === props.value)?.title || props?.label}
      />
    );
  };

const singleOptionRenderer = (option) => {
  return (
    <Option key={`option-${option.value}`} value={option.value} title={option.title}>
      <span className="spsg-select-dropdown-label" title={option.title}>
        {option.title}
      </span>
      <CheckSquareFilled />
    </Option>
  );
};

const nestedOptionsRenderer = (option, selected, singleOptionRender, onSelect) => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setToggle((prev) => !prev);
  };

  const checkIconClassName = useMemo(() => {
    const selectedSet = new Set(selected);
    const selectedCount = option.children.reduce((count, nestedOption) => {
      if (selectedSet.has(nestedOption.value)) {
        return count + 1;
      }
      return count;
    }, 0);

    if (selectedCount === option.children.length) {
      return 'selected';
    }
    if (selectedCount > 0) {
      return 'intermediate';
    }
    return '';
  }, [selected, option]);

  return (
    <OptGroup
      key={option.value}
      value={option.value}
      label={
        <div
          tabIndex={0}
          role="button"
          className="ant-select-item-option-content"
          onClick={() => onSelect(option.value)}
        >
          <div
            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            tabIndex={0}
            role="button"
            onClick={handleToggle}
          >
            {toggle ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
            <span className="spsg-select-dropdown-label" style={{ color: 'initial' }} title={option.title}>
              {option.title}
            </span>
          </div>
          <CheckSquareFilled className={checkIconClassName} />
        </div>
      }
    >
      {toggle && option.children.map((opt) => singleOptionRender(opt))}
    </OptGroup>
  );
};

const DashboardSelectDropdown = ({
  options,
  selected,
  onSelect,
  onClear,
  placeholder,
  disabled = false,
  showAll = true,
  showSearch = true,
  allowClear = true,
  loading = false,
  tagRender = tagRenderer,
  className = undefined,
  singleOptionRender = singleOptionRenderer,
  nestedOptionsRender = nestedOptionsRenderer,
  maxTagPlaceholder = (omitted) => <TagPlaceholder omitted={omitted} selected={selected} showAll={showAll} />,
  maxTagCount = showAll && selected.includes('All') ? 0 : 1,
  filterOption = (input, option) =>
    option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}) => {
  const optionsRender = (option) => {
    if (option?.children) {
      return nestedOptionsRender(option, selected, singleOptionRender, onSelect);
    }

    return singleOptionRender(option);
  };

  return (
    <Select
      allowClear={allowClear}
      showSearch={showSearch}
      virtual
      getPopupContainer={(trigger) => trigger.parentElement}
      className={classNames('spsg-select-multiple diagnose-dashboard-select', className)}
      dropdownClassName={'spsg-select-multiple-drodown diagnose-dashboard-select-popup'}
      menuItemSelectedIcon={null}
      mode={'multiple'}
      optionFilterProp={'name'}
      tagRender={tagRender}
      loading={loading}
      filterOption={filterOption}
      maxTagPlaceholder={maxTagPlaceholder}
      maxTagCount={maxTagCount}
      value={selected}
      onSelect={onSelect}
      onDeselect={onSelect}
      onClear={onClear}
      disabled={disabled}
      placeholder={placeholder}
    >
      {showAll && options?.length && (
        // title expected to filter data on search
        <Option key={'option-all'} value={'All'} title={'All'}>
          <span className="spsg-select-dropdown-label" title={'All'}>
            All
          </span>
          <CheckSquareFilled />
        </Option>
      )}
      {options?.map(optionsRender)}
    </Select>
  );
};

export default DashboardSelectDropdown;
