import React from "react";
import { Input, Space } from "antd";
import PropertySelection from "../../../common/PropertySelection";
import { fromJS } from "immutable";
import { Typography } from "antd";
import { theme } from "antd";

const { useToken } = theme;

const NameAndProperties= (props) => {
  console.time("NameAndPropertiesVL"); //to check rendering

  const updateCategoryName = (name) => {
    const updatedConfig = props.configuration.set('name', name)
    props.updateConfiguration((config) => config.set('name', name));
  }

  const updateSelectedProperties = (propertyIds) => {
    props.updateConfiguration((config) => config.set('propertyIds', fromJS(propertyIds)));
  }

  const configName = props.configuration.get('name')
  const regulationNameError = configName.trim().length === 0 ? "Please Enter Configuration Name" : props.preferenceConfigurationNamesList?.includes(configName.trim()) ? <>Configuration with name <b>{configName}</b> already exists</> : null
  console.timeEnd("NameAndPropertiesVL"); //to check rendering

  const { token } = useToken();

  return (
    <>
      {!props.isEditFlow ? <div className="step-breif">This wizard will guide you through the settings that are required for the initial setup of preference configuration that will apply to the messages on your properties that fall under one or more regulations.</div> : null}
      <div className="data-field">
        <Space block direction="vertical" size={token.marginXXS}>
          <Typography.Text>Configuration Name</Typography.Text>
          <Input
            style={{width: token.controlHeight * 10}}
            placeholder="Enter Name"
            value={configName}
            onChange={({target: {value}}) => {
              updateCategoryName(value);
            }}
            error={props.showError && regulationNameError ? regulationNameError : null}
            maxLength={60}
            showCount={true}
          />
        </Space>
      </div>
      <div className="step-header">
        <Typography.Title level={4}>Property Selection</Typography.Title>
      </div>
      <div className="step-breif">
        <Typography.Text>Select the properties or property groups you wish this configuration to apply to.</Typography.Text>
      </div>
      <Typography.Text type="danger">{props.showError && props.configuration.get('propertyIds').size == 0? "Select at least one property" : null}</Typography.Text>
      <div className="step-section">
        <div className={`section-content ${props.showError && props.configuration.get('propertyIds').size == 0 ? 'validation-error' : null}`}>
          <PropertySelection
            selectedPropertiesKeys={props.configuration.get('propertyIds')?.toJS()}
            setSelectedPropertiesKeys={updateSelectedProperties}
            sites={props.sites}
            siteGroups={props.siteGroups}
          />
        </div>
      </div>
    </>
  )
}

export default NameAndProperties