import React, { useEffect, useState, useMemo } from "react";
import { getParameterByName, findObjectDifferences } from "../../../utils";
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { getSiteGroups, getAllSites } from "../../../../actions/site_actions";
import { Map, List, OrderedSet, fromJS } from "immutable";
import { browserHistory } from "react-router";
import { verifyMSPASinatoryId } from "../../../../api/usnat/vendor_list";
import {
  getUSPrivacyRegulation,
  updateRegulation,
  getSystemPurposes,
  getAccountList,
  getSystemStacks,
  clearRegulationError,
  getGlobalVendors
} from "../../../../actions/us_privacy_regulation_actions";
import { useActions } from "../../../../hooks";
import { USPrivacyRegulation } from "../../../../records/us_privacy_regulation_records";
import { Button,  message } from "../../../../styleguide";
import {
  AdvancedSettings,
  OptInAndOptOut,
  IndustryFramework,
  LanguageTranslations,
  NameAndProperty,
  SelectPurposes,
  VendorManagement
} from "../NewPrivacyRegulation/Steps";
import { evaluateRequriedFieldsFromStep, stepsData, updateCategoryCache, renderInfoTip, renderTranslationWarningError, validateTranslation, valideAllStepsBeforeSave } from "../helper";
import Loading from "../../../common/Loading.js";
import DismissErrorModal from "../../../common/DismissErrorModal";
import { InfoCircleFilled } from "@ant-design/icons";
import { updateStepsDataFunction, getStepsDataFieldMap, getOrderedStepsKeys } from "../../../common/SequentialNavigationWizard/SequentialLeftNav.jsx";
import { handleServerSideErrors, renderCannotUpdateRegulationError } from "../../vendor_list_v2/helper";
import { usPrivacyRegulationContext } from "../contexts";
import { Alert, Tabs } from "antd";

const TabPane = Tabs.TabPane;
const sd = stepsData.map(item => ({
  ...item,
  ...(item.steps ? { steps: item.steps.map(step => ({
    ...step,
    status: 'finish',
    title: step.title.replace('Selection', '')
  }))} : { status: 'finish'})
}));

const USPrivacyRegulationEdit = (props) => {
  const [usPrivacyRegulation, setUSPrivacyRegulation] = useState(null);
  const [intermediateUSPRegulation, setIntermediateUSPRegulation] = useState(null);
  const [stepsDataForEditFlow, setStepsDataForEditFlow] = useState(fromJS(sd));
  const [current, setCurrent] = useState("name_property_selection");
  const [currentRelay, setCurrentRelay] = useState(null);
  const [exitEditModal, setExitEditModal] = useState(false)
  const [showTranslationModal, setShowTranslationModal] = useState(false)
  const [translationErrorContent, setTranslationErrorContent] = useState(null);

  //for vendors context
  const [vendorsIdsMap, setVendorIdsMap] = useState(props.globalVendors?.reduce((map, item) => {
    map[item.id] = item.ids;
    return map;
  }));
  useEffect(()=>{
    if(props.globalVendors.size){
      setVendorIdsMap(props.globalVendors.reduce((map, item) => {
        map[item.id] = item.ids;
        return map;
      }, {}))
    }
  },[props.globalVendors])


  //server side error
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [serverErrors, setServerErrors] = useState();

  //global categories cache
  const [regulationCategoryOptionsCache, setRegulationCategoryOptionsCache] = useState(null)

  const regulationId = getParameterByName("regulationId");

  //actions and redux state
  const regulationsList = useSelector(state => state.usPrivacyReducerState.getIn(['vendorList', 'value'])).filter(regulation => regulation.name !== usPrivacyRegulation?.name);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']));
  const systemStacks = useSelector(state => state.usPrivacyReducerState.getIn(['stacks', 'value']));
  const regulationListsErrorState = useSelector(state => state.usPrivacyReducerState.getIn(['vendorList', 'error']));
  const regulationSavePending = useSelector(state => state.usPrivacyReducerState.get('savePending'));
  const clearRegulationErrorAction = useActions(clearRegulationError);
  const getUsPrivacyRegulationAction = useActions(getUSPrivacyRegulation);
  const getPurposes = useActions(getSystemPurposes);
  const getStacks = useActions(getSystemStacks);
  const updateRegulationAction = useActions(updateRegulation);
  const getUSPrivacyAccountsList = useActions(getAccountList);

  const stepsKeyNameMap = getStepsDataFieldMap(stepsDataForEditFlow, 'title')
  const stepsErrorMap = getStepsDataFieldMap(stepsDataForEditFlow, 'error')
  const stepsWarningMap = getStepsDataFieldMap(stepsDataForEditFlow, 'warning');

  // delete stepsKeyNameMap['summary'];
  const indexSiteNameMap = useMemo(() => props.sites.toJS().reduce((map, obj) => (map[obj.id] = obj.domain, map), {}), [props.sites]);

  //server side error
  useEffect(() => {
    if(regulationListsErrorState){
      const serverErrors = handleServerSideErrors(regulationListsErrorState, indexSiteNameMap);
      if(serverErrors){
        setShowErrorModal(true)
        setServerErrors(serverErrors)
      }
    }
    return ()=>{
      clearRegulationErrorAction();
    }
  },[regulationListsErrorState]);

  useEffect(() => {
    (async () => {
      const systemPurposes = await getPurposes();
      getStacks(systemPurposes);
    })();
    props.getSiteGroups(props.currentUser);
    props.getAllSites();
    props.getGlobalVendors();
    getUSPrivacyAccountsList();
  }, []);

  useEffect(()=>{
    let categoriesCache = List([])
    if(intermediateUSPRegulation){
      const onlyPurposesNotInStack = systemPurposes.filter( purpose => !systemStacks.some( stack => stack.categories.map( c => c.purposeRef.id).includes(purpose.purposeRef.id)))
      
      onlyPurposesNotInStack.forEach((purpose) => {
        categoriesCache = categoriesCache.push(purpose);
      });
      systemStacks.forEach((stack)=>{
        categoriesCache = categoriesCache.push(stack);

      })
      categoriesCache = updateCategoryCache(categoriesCache, intermediateUSPRegulation, true)

      setRegulationCategoryOptionsCache(categoriesCache)
    }
  },[systemStacks, intermediateUSPRegulation])

  useEffect(() => {
    (async () => {
      if (props.currentUser.accountId) {
        let usPrivacyRegulation = await getUsPrivacyRegulationAction(regulationId, props.currentUser.accountId);
        if(usPrivacyRegulation.get('signatoryId')) {
          try {
            const signatory = await verifyMSPASinatoryId(usPrivacyRegulation.signatoryId);
            if(signatory.id){
              usPrivacyRegulation = usPrivacyRegulation.set('signatoryName', signatory.name);
            }
          }catch (err) {
            if(err) {
              message.error(<>Identification ID {usPrivacyRegulation.get('signatoryId')} is Invalid.</>)
              // usPrivacyRegulation = usPrivacyRegulation.set('signatoryId', signatory.name);
            }
          }
        }
        setUSPrivacyRegulation(usPrivacyRegulation);
        setIntermediateUSPRegulation(usPrivacyRegulation?.toJS());
        if(usPrivacyRegulation?.get("deletedVendors")?.size){
          setStepsDataForEditFlow(updateStepsDataFunction(stepsDataForEditFlow, "vendor_management", 'warning', true));
        }
      }
    })();
  }, [props.currentUser]);

  const shouldStopFlowForTranslationError = () =>{
    const translationError = validateTranslation(intermediateUSPRegulation)
    if(translationError) {
      setTranslationErrorContent(translationError)
      setShowTranslationModal(true)
      return true;
    }
    return false
  }

  const handleSaveRegulation = () => {
    const stepsWithError = valideAllStepsBeforeSave(getOrderedStepsKeys(stepsDataForEditFlow), intermediateUSPRegulation, regulationsList)
    if(stepsWithError.length == 0) {
      updateRegulationAction(
        props.currentUser.accountId,
        new USPrivacyRegulation(intermediateUSPRegulation)
      ).then((resp) => {
        if (resp?.name) {
          message.success(
            <>
              Regulation <b>{resp.name}</b> has been updated
            </>
          );
          setUSPrivacyRegulation(resp);
          setIntermediateUSPRegulation(resp?.toJS());
          if(resp?.get("deletedVendors")?.size){
            setStepsDataForEditFlow(updateStepsDataFunction(stepsDataForEditFlow, "vendor_management", 'warning', true));
          }
        }
      }).catch((err) => {
        message.error(
          <>
            Regulation could not be saved 
          </>
        );
      });
    } else {
      setCurrent(stepsWithError[0]);
      stepsWithError.forEach(step => updateShowError(step, true))
    }
  };

  const handleExitEditRegulation = () => {
    const isUnsavedChangesPresent = findObjectDifferences(usPrivacyRegulation?.toJS(), intermediateUSPRegulation)
    if (!_.isEmpty(isUnsavedChangesPresent)) {
      setExitEditModal(true);
    } else {
      browserHistory.push("/us_privacy/regulations")
    }
  }

  const onChange = (value, skipTranslationErrorCheck = true) => {
    if (evaluateRequriedFieldsFromStep(current, intermediateUSPRegulation, regulationsList)) {
      updateShowError(current, false);
      if (skipTranslationErrorCheck && (current === 'language_translations' && shouldStopFlowForTranslationError())) {
        setCurrentRelay(value);
        return;
      }
      if(current == 'language_translations' || current == 'opt_in_opt_out' || current == 'vendor_management') {
        setRegulationCategoryOptionsCache(updateCategoryCache(regulationCategoryOptionsCache, intermediateUSPRegulation, false));
        setStepsDataForEditFlow(updateStepsDataFunction(stepsDataForEditFlow, current, 'warning', false));
      }
      setCurrent(value);
    } else {
      updateShowError(current, true);
    }
  };

  const updateShowError = (step, isIncomplete) => {
    setStepsDataForEditFlow(updateStepsDataFunction(stepsDataForEditFlow, step, 'error', isIncomplete));
  };

  const resetValidationError = (step) => {
    if(evaluateRequriedFieldsFromStep(step, intermediateUSPRegulation, regulationsList)){
      updateShowError(step, false)
    }
  }
  const changesDetectedAlert = (
    <Alert showIcon style={{marginBottom: "16px"}} icon={<InfoCircleFilled className="info-icon" />} message={<div>Updates made in the <b>Privacy Choice Selection</b> step have resulted in new modifications in the current step. <b>Please review the recent updates before proceeding.</b></div>} type="warning"/>
  )

  let readOnly = false;
  if (props.currentUser?.featureAccess &&
    !props.currentUser?.featureAccess?.includes('Vendor List - U.S. Multi-State')) {
    readOnly = true;
  }

  const renderWizardStepComponent = (key) => {
    let component;
    switch (key) {
      case "name_property_selection":
        component = (
          <NameAndProperty
            usPrivacyRegulation={intermediateUSPRegulation}
            showError={stepsErrorMap.get("name_property_selection")}
            resetValidationError={() => resetValidationError("name_property_selection")}
            sites={props.sites}
            siteGroups={props.siteGroups}
            currentUser={props.currentUser}
            regulationsList={regulationsList}
            isEditFlow={true}
            readOnly={readOnly}
          />
        );
        break;
      case "industry_framework":
        component = (
          <IndustryFramework
            usPrivacyRegulation={intermediateUSPRegulation}
            showError={stepsErrorMap.get("industry_framework")}
            resetValidationError={() => resetValidationError("industry_framework")}
            readOnly={readOnly}
          />
        );
        break;
      case "select_puropses":
        component = (
          <SelectPurposes
            usPrivacyRegulation={intermediateUSPRegulation}
            showError={stepsErrorMap.get("select_puropses")}
            resetValidationError={() => resetValidationError("select_puropses")}
            goToSignatoryIdStep={() => onChange("industry_framework")}
            regulationCategoryOptionsCache={regulationCategoryOptionsCache}
            stepsData={stepsDataForEditFlow}
            setStepsData={setStepsDataForEditFlow}
            current={current}
            readOnly={readOnly}
          />
        );
        break;
      case "language_translations":
        component = (
          <LanguageTranslations
            usPrivacyRegulation={intermediateUSPRegulation}
            changesDetectedWarning={stepsWarningMap.get("language_translations") ? changesDetectedAlert : null}
            showError={stepsErrorMap.get("language_translations")}
            readOnly={readOnly}
            resetValidationError={() => resetValidationError("language_translations")}
          />
        );
        break;
      case "vendor_management":
        component = (
          <VendorManagement
            usPrivacyRegulation={intermediateUSPRegulation}
            changesDetectedWarning={stepsWarningMap.get("vendor_management") ? changesDetectedAlert : null}
            readOnly={readOnly}
          />
        );
        break;
      case "opt_in_opt_out":
        component = (
          <OptInAndOptOut
            usPrivacyRegulation={intermediateUSPRegulation}
            changesDetectedWarning={stepsWarningMap.get("opt_in_opt_out") ? changesDetectedAlert : null}
            readOnly={readOnly}
          />
        );
        break;
      case "signal_settings":
        component = (
          <AdvancedSettings usPrivacyRegulation={intermediateUSPRegulation} readOnly={readOnly}/>
        );
        break;
    }
    return regulationSavePending ? <Loading/> : component;
  };

  const steps = intermediateUSPRegulation
    ? getOrderedStepsKeys(stepsDataForEditFlow).filter(key => key !== 'summary').map((key) => {
      let warningIcon = stepsWarningMap.get(key) ? renderInfoTip("Changes Detected", "warning") : null;
      warningIcon = stepsErrorMap.get(key) ? renderInfoTip("Error(s) found", "error-icon") :  warningIcon;
      return (
        <TabPane
          className="edit-us-privacy-component"
          tab={<>{stepsKeyNameMap.get(key)}{warningIcon}</>}
          key={key}
        >
          {renderWizardStepComponent(key)}
        </TabPane>
      )})
    : null;

  const handleResumeWizardFlow = (e) => {
    e.preventDefault();
    setShowTranslationModal(false);
    onChange(currentRelay, false)
  }

  const failedToUpdateRegulationError = renderCannotUpdateRegulationError(intermediateUSPRegulation, serverErrors);

  return !intermediateUSPRegulation ? (
    <Loading />
  ) : (
    <>
    <div className="us-privacy-regulation-edit">
      <div className="back-link pointer" onClick={handleExitEditRegulation}>
        {`< Back`}
      </div>
      <div className="header">
        <div>
          <b>{intermediateUSPRegulation?.name}</b> - Regulation Details
        </div>

        <div>
          <Button type="secondary cancel"  onClick={handleExitEditRegulation}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSaveRegulation} disabled={readOnly}>
            Save
          </Button>
        </div>
      </div>
      <usPrivacyRegulationContext.Provider value={{vendorsIdsMap}}>
      <Tabs
        defaultActiveKey="name_property_selection"
        activeKey={current}
        onChange={onChange}
        destroyInactiveTabPane={true}
      >
        {steps}
      </Tabs>
      </usPrivacyRegulationContext.Provider>
    </div>
    { props.pendingRequestsMap.get('regulation') ? <Loading/> : null }
    { exitEditModal ? 
      <DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title={"Unsaved Changes"}
        error={<><div>There are <b>unsaved changes</b>. If you leave this page, you will lose all the changes you have made.</div> <br/><div>Are you sure you wish to leave the page?</div></>}
        isModalVisible={exitEditModal}
        handleCancel={()=>{browserHistory.push("/us_privacy/regulations"); setExitEditModal(false)}}
        renderOk
        okText={"Keep Editing"}
        cancelText={"Leave without saving"}
        handleOk={()=>{setExitEditModal(false)}}
        primaryAction="submit"
        dontShowCross={true}
      /> : null}
    {showErrorModal && (<DismissErrorModal
      modalWrapperClass="activate-confirmation"
      title={"Activate Regulation"}
      error={failedToUpdateRegulationError}
      isModalVisible={showErrorModal}
      handleCancel={() => setShowErrorModal(false)}
      renderOk={false}
      okText={"OK"}
      cancelText={"Close"}
      handleOk={() => setShowErrorModal(false)}
      primaryAction="submit"
    />)}
    { showTranslationModal ?
      <DismissErrorModal
        title={"Missing Translations"}
        modalWrapperClass="activate-confirmation"
        error={renderTranslationWarningError(translationErrorContent)}
        isModalVisible={showTranslationModal}
        handleCancel={() => setShowTranslationModal(false)}
        renderOk={true}
        okText={"Continue"}
        cancelText={"Keep Editing"}
        handleOk={handleResumeWizardFlow}
        primaryAction="submit"
      /> : null}
    </>
  );
};
const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']) ?? {},
    sites: store.siteState.getIn(['sites', 'value'])?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')),
    siteGroups: store.siteState
      .getIn(['siteGroups', 'value'])
      .sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      ),
    globalVendors: store.usPrivacyReducerState.getIn(['mspsSystemVendors', 'value']) ?? OrderedSet([]),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      siteGroups: store.siteState.getIn(['siteGroups', 'pending']),
      regulation: store.usPrivacyReducerState.getIn(['savePending'])
    }),
  };
};

export default connect(mapStateToProps, {
  getSiteGroups,
  getAllSites,
  getGlobalVendors
})(USPrivacyRegulationEdit);
