import React, { useEffect, useState } from "react";
import { Select } from "../../../../../../styleguide";
import PropertySelection from "../../../../../common/PropertySelection";
import { CONSENT_SCOPE_TOOLTIP, renderInfoTip } from "../../../helper";
import { findNthTermInAnEntityList } from "../../../../../utils";
import { Space, Typography, Input, Alert } from "antd";
import { theme } from "antd";

const { useToken } = theme;

const NameAndProperty = (props) => {
  
  const [ regulationName, setRegulationName ] = useState(props.usPrivacyRegulation.name ?? "");
  const [ selectedPropertiesKeys, setSelectedPropertiesKeys ] = useState(props.usPrivacyRegulation.siteIds);
  const [ consentScope, setConsentScope ] = useState(props.usPrivacyRegulation.consentScope);
  const [ regulationNameError, setRegulationNamenError ] = useState(null);
  const [ siteIdsError, setSiteIdsError ] = useState(false);

  useEffect(()=>{
    props.usPrivacyRegulation.name = regulationName;
    props.usPrivacyRegulation.siteIds =  selectedPropertiesKeys;
    props.usPrivacyRegulation.consentScope = consentScope;
    props.resetValidationError();
  },[regulationName, selectedPropertiesKeys, consentScope])


  useEffect(() => {
    if (props.showError) {
      const isRegulationNameEmpty = regulationName.trim().length === 0;
      const isRegulationNameExists = props.regulationsList?.some(al => al.name === regulationName.trim());
      setRegulationNamenError(
        isRegulationNameEmpty
          ? "Please Enter Regulation Name"
          : isRegulationNameExists
          ? <>Regulation with name <b>{regulationName}</b> already exists</>
          : null
      );
  
      setSiteIdsError(
        selectedPropertiesKeys.length === 0
          ? "Select at least one property"
          : false
      );
    } else {
      setRegulationNamenError(null);
      setSiteIdsError(false);
    }
  }, [props.showError, regulationName, selectedPropertiesKeys]);

  useEffect(() => {
    if(!props.isEditFlow && props.regulationsList.size && !Boolean(props.usPrivacyRegulation.name)){
      setRegulationName(findNthTermInAnEntityList(props.regulationsList.map(reg => reg.name), "New Regulation"))
    }
  },[props.regulationsList]);

  const { token } = useToken();

  return (
    <>
      {props.readOnly ? <div className="step-breif"><Alert type="error" message={<><b>View Only:</b> User does not has access to U.S. Multi State Regulations</>}/> </div> : null}
      {!props.isEditFlow ? <div className="step-breif">This wizard will guide you through settings that are required to set up a privacy framework for users on your properties that fall under <b>US state privacy regulations.</b></div> : null}
      <div className="data-field">
      <Space block direction="vertical" size={token.marginXXS}>
        <Typography.Text>Regulation Name</Typography.Text>
        <Input
          style={{width: token.controlHeight * 10}}
          placeholder="Enter Name"
          value={regulationName}
          onChange={({target: {value}}) => {
            setRegulationName(value);
          }}
          error={regulationNameError}
          maxLength={60}
          showCount={true}
          disabled={props.readOnly}
        />
      </Space>
      </div>
      <div className="step-header">
        <Typography.Title level={4}>Property Selection</Typography.Title>
      </div>
      <div className="step-breif"><Typography.Text>Select the properties or property groups you wish these regulation settings to apply to.</Typography.Text></div>
      <div className="step-error">{siteIdsError}</div>
      <div className="step-section">
        <div className={`section-content ${siteIdsError ? 'validation-error' : null}`}>
          <PropertySelection
            selectedPropertiesKeys={selectedPropertiesKeys}
            setSelectedPropertiesKeys={setSelectedPropertiesKeys}
            sites={props.sites}
            siteGroups={props.siteGroups}
          />
        </div>
      </div>
      <div className="step-header">
        <Typography.Title level={4}>Consent Scope</Typography.Title>
        {renderInfoTip(CONSENT_SCOPE_TOOLTIP)}
      </div>
      <div className="step-breif">
        <Select
          value={consentScope}
          onChange={(value) => {setConsentScope(value)}}
          size="large"
          options={[{label: 'Single site', value: 'SINGLE'}, {label: 'Shared site', value: 'GROUP'}]}
          disabled={props.readOnly}
        />
      </div>
    </>
  )
}

export default NameAndProperty;
