import React, { useEffect, useState } from 'react';
import { connect,useSelector } from 'react-redux';
import { evaluateRequriedFieldsFromStep, preferences_steps, valideAllStepsBeforeSave } from './helper';
import { Configuration } from "../../../records/preferences_records.js";
import SequentialLeftNav, { getOrderedStepsKeys, updateStepsDataFunction, getStepsDataFieldMap } from '../../common/SequentialNavigationWizard/SequentialLeftNav';
import NameAndProperties from './Screens/NameAndProperties';
import AddCategories from './Screens/AddCategories';
import LanguageAndTranslations from './Screens/LanguageAndTranslations';
import IntegrationCategoryMapping from './Screens/IntegrationCategoryMapping';
import Summary from './Screens/Summary';
import moment from 'moment';
import { List, Map, fromJS } from 'immutable';
import { Alert, message } from '../../../styleguide';
import DismissErrorModal from '../../common/DismissErrorModal';
import Loading from '../../common/Loading.js';
import { InfoCircleFilled, CloseOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import { getSiteGroups, getAllSites } from '../../../actions/site_actions.js';
import { usePrevious } from '../../../hooks.js';
import { clearPreferenceConfigurationError, createPreferenceConfiguration, getAllPreferenceConfiguration, getAllVendors } from '../../../actions/preferences_actions.js';
import { browserHistory } from 'react-router';
import { findNthTermInAnEntityList } from '../../utils.js';
import { Modal, Typography, Button, theme } from 'antd';

const { useToken } = theme;

const CreateConfiguration = (props) => {
  const actualComponents = getOrderedStepsKeys(fromJS(preferences_steps));
  const [current, setCurrent] = useState(actualComponents[0]);
  const [stepsData, setStepsData] = useState(fromJS(preferences_steps));
  const [dontFinishCurrentYet, setDontFinishCurrentYet] = useState(false);
  const isLastStep = actualComponents.indexOf(current) === actualComponents.length - 1;
  const [openExitWizardModal, setOpenExitWizardModal] = useState(false);
  const [configuration, setConfiguration] = useState(new Configuration({}));

  useEffect(() => {
    if(props.preferenceConfigurationServerSideError && typeof props.preferenceConfigurationServerSideError !== "string"){
        let content; 
        if(props.preferenceConfigurationServerSideError?.response?.status == 500){
          Modal.error({
            title: "Failed to save Configuration",
          })
          return;
        }
        const errors = props.preferenceConfigurationServerSideError.get('errors');
        const validationError = props.preferenceConfigurationServerSideError.get('err');
        if(props.preferenceConfigurationServerSideError.get('errors')?.size){
          content = <ul>{errors.map((error) => <li>{error.msg}</li>)}</ul>
        } else if(validationError){
          content = validationError
        }
        Modal.error({
          title: "Failed to save Configuration",
          content: content
        })
    }
    return ()=>{
      props.clearPreferenceConfigurationError();
    }
  },[props.preferenceConfigurationServerSideError]);

  const prevCurrent = usePrevious(current);
  useEffect(() => {
    setStepsData((stepsData) => {
      let updatedStepsData = updateStepsDataFunction(stepsData, prevCurrent, "status", dontFinishCurrentYet ? "wait" : "finish");
      updatedStepsData = updateStepsDataFunction(updatedStepsData, current, "status", "process");
      return updatedStepsData;
    })
  }, [current]);

  useEffect(()=>{ 
    if(props.currentUser){
      props.getSiteGroups(props.currentUser);
      props.getAllPreferenceConfiguration(props.currentUser?.accountId);
      setConfiguration(configuration.set("accountId", props.currentUser?.accountId))
    }
    props.getAllSites();
    props.getAllVendors();
  }, [props.currentUser]);
  
  useEffect(()=>{
    if(evaluateRequriedFieldsFromStep(current, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
      updateShowError(current, false)
    }
  },[configuration])

  useEffect(()=>{
    if(!props.pendingRequestsMap.get("preferenceConfigurationNamesList") && props.preferenceConfigurationNamesList.size && !Boolean(configuration.get("name"))){
      setConfiguration(configuration.set("name", findNthTermInAnEntityList(props.preferenceConfigurationNamesList, "New Configuration")))
    }
  },[props.preferenceConfigurationNamesList])

  const updateConfiguration = (regulation) => {
    setConfiguration(regulation)
  }


  const onChange = (value) => {
    const currentIndex = actualComponents.findIndex((a) => a === current);
    const jumpToIndex = actualComponents.findIndex((a) => a === value);

    if(currentIndex === jumpToIndex){
      return;
    } else if(jumpToIndex < currentIndex){
      onPrevious(value);
    } else {
      onNext(value);
    }
  };

  const onNext = async (jumpTo) => {
    setDontFinishCurrentYet(false);
    const nextStepIndex = actualComponents.findIndex((a) => a === current) + 1;
    if (isLastStep) {
      const stepsWithError = valideAllStepsBeforeSave(actualComponents, configuration?.toJS(), props.preferenceConfigurationNamesList)
        if(stepsWithError.length == 0){
          try {
            const resp = await props.createPreferenceConfiguration(configuration);
      
            if (resp?.name) {
              message.success(
                <>
                  Regulation <b>{resp.name}</b> has been successfully created
                </>
              );
              browserHistory.push("/preferences");
            }
          } catch (error) {
            // Handle error if needed
          }
        }else {
          setCurrent(stepsWithError[0]);
          stepsWithError.forEach(step => updateShowError(step, true))
        }
      return null;
    }
    if(evaluateRequriedFieldsFromStep(current, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
      setCurrent(jumpTo ?? actualComponents[nextStepIndex]);
      updateShowError(current, false)
    } else {
      updateShowError(current, true);
    }
  };  

  const onPrevious = (jumpTo) => {
    setDontFinishCurrentYet(false);
    const nextStepIndex = actualComponents.findIndex((a) => a == current) - 1;

    if(!evaluateRequriedFieldsFromStep(current, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
      setDontFinishCurrentYet(true)
    }
    setStepsData((stepsData) => updateStepsDataFunction(stepsData, current, 'warning', false));
    setCurrent(jumpTo ?? actualComponents[nextStepIndex]);
    updateShowError(current, false)
  };

  const updateShowError = (step, isIncomplete) => {
    setStepsData((stepsData) => updateStepsDataFunction(stepsData, step, 'error', isIncomplete));
  };

  const resetValidationError = (step) => {
    if(evaluateRequriedFieldsFromStep(step, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
      updateShowError(step, false)
    }
  }

  const changesDetectedAlert = (
    <Alert showIcon icon={<InfoCircleFilled className="info-icon" />} message={<div>Updates made in the <b>Privacy Choice Selection</b> step have resulted in new modifications in the current step. <b>Please review the recent updates before proceeding.</b></div>} type="warning"/>
  )
  const renderWizardStepComponent = () => {
    let component;
    switch (current) {
      case "name_properties":
        component = (
          <NameAndProperties
            sites={props.sites}
            siteGroups={props.siteGroups}
            currentUser={props.currentUser}
            configuration={configuration}
            updateConfiguration={setConfiguration}
            preferenceConfigurationNamesList={props.preferenceConfigurationNamesList}
            showError={stepsErrorMap.get('name_properties')}
          />
        );
        break;
      case "add_categories":
        component = (
          <AddCategories
            readOnly={false}
            configuration={configuration}
            categories={configuration?.categories}
            updateConfiguration={updateConfiguration}
            showError={stepsErrorMap.get('add_categories')}
          />
        );
        break;
      case "language_translations":
        component = (
          <LanguageAndTranslations
            readOnly={false}
            regulation={configuration}
            categories={configuration?.categories}
            updateConfiguration={updateConfiguration}
          />
        );
        break;
      case "category_mapping":
        component = (
          <IntegrationCategoryMapping
            configuration={configuration}
            updateConfiguration={updateConfiguration}
            showError={stepsErrorMap.get('category_mapping')}
          />
        );
        break;
      case "summary":
        component = (
          <Summary
            stepArray={actualComponents}
            goToStep={onChange}
            configuration={configuration}
            sitesNameMap={props.sitesNameMap}
          />
        );
        break;
    }
    return false ? <Loading/> : component;
  };

  const stepsNamesMap = getStepsDataFieldMap(stepsData, 'title');
  const stepsErrorMap = getStepsDataFieldMap(stepsData, 'error');

  const { token } = useToken();
  
  return configuration ? (
  <>
    <div className="new-us-privacy-regulations-wizard">
      <div className="header">
        <Typography.Title level={4} style={{margin: 0}}>Configurations Setup</Typography.Title>
        <Button className='close-wizard-btn' icon={<CloseOutlined/>} onClick={()=>setOpenExitWizardModal(true)}/>
      </div>
      <div className="content">
        <div className="wizard-left-nav">
          <SequentialLeftNav
            stepsData={stepsData}
            onChange={(value) => onChange(value)}
            entityState={configuration}
          />
        </div>
        <div className="wizard-layout">
          <div id="wizard-step-component">
            {renderWizardStepComponent()}
          </div>

          <div className="bottom-nav">
           {actualComponents.indexOf(current) !== 0 ?  <Button
              icon={<LeftOutlined/>}
              type="default"
              onClick={() => onPrevious()}
              disabled={actualComponents.indexOf(current) === 0}
            >
              {stepsNamesMap.get(actualComponents[actualComponents.indexOf(current) - 1])}
            </Button> : null}
            <Button
              type="primary"
              icon={isLastStep ? null : <RightOutlined />}
              iconPosition="end"
              onClick={() => onNext()}
              disabled={stepsErrorMap.get(current) || props.pendingRequestsMap.get("savePending")}
            >
              {isLastStep
                ? "Save"
                : `${stepsNamesMap.get(actualComponents[actualComponents.indexOf(current) + 1])}`}
            </Button>
          </div>
        </div>
      </div>
    </div>
    { openExitWizardModal ? 
    <DismissErrorModal
      modalWrapperClass="activate-confirmation"
      title={"Exit Wizard"}
      error={<><div>All the data will be lost if you exit the wizard without saving the Configuration.</div> <div>Are you sure you want to exit the wizard?</div></>}
      isModalVisible={openExitWizardModal}
      handleCancel={()=>setOpenExitWizardModal(false)}
      renderOk
      okText={"Exit"}
      cancelText={"Cancel"}
      handleOk={()=>{browserHistory.push("/preferences/"); setOpenExitWizardModal(false)}}
      primaryAction="submit"
      /> : null}
  </>) : <Loading/>;
};

const mapStateToProps = function (store) {
  return {
  currentUser: store.accountState.getIn(['userDetails', 'value']),
  sitesNameMap: store.siteState.getIn(['sitesNameMap', 'value']),
  sites: store.siteState.getIn(['sites', 'value'])?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')),
  siteGroups: store.siteState
    .getIn(['siteGroups', 'value'])
    .sort(
      (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
    ),
  preferenceConfigurationNamesList: store.preferencesState.getIn(['preferenceConfigurationList', 'value'])?.map((con) => con.name) ?? List([]),
  preferenceConfigurationServerSideError: store.preferencesState.getIn(["preferenceConfigurationList", "error"]),
  pendingRequestsMap: Map({
    savePending: store.preferencesState.get('savePending'),
    sites: store.siteState.getIn(['sites', 'pending']),
    siteGroups: store.siteState.getIn(['siteGroups', 'pending']),
    preferenceConfigurationNamesList: store.preferencesState.getIn(['preferenceConfigurationList', 'pending'])
  }),
  };
};

export default connect(mapStateToProps, {
  getSiteGroups,
  getAllSites,
  getAllVendors,
  getAllPreferenceConfiguration,
  createPreferenceConfiguration,
  clearPreferenceConfigurationError
})(CreateConfiguration);