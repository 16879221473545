import React, { useState, useEffect } from 'react';
import { Table, Form, Tooltip, Button } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons"
import { Input, Modal, Popconfirm } from "../../../../../styleguide";
import SVG from 'react-inlinesvg';
import editIcon from '../../../../../assets/icons/pencil.svg';
import cancelIcon from '../../../../../assets/icons/cross.svg';
import saveIcon from '../../../../../assets/icons/check.svg';
import { renderInfoTip } from "../../../helper";

const { TextArea } = Input;

const WARNING_TEXT = "For empty translation fields, Default Language(English) Translation will be used."


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  type,
  activeTab,
  ...restProps
}) => {

  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text');
    const cleanedText = text.trim();
    document.execCommand('insertText', false, cleanedText);
  };

  const inputNode = record?.translation?.length < 50 ? <Input onPaste={handlePaste} /> : <TextArea rows={3} />;
  const validationCondition = (activeTab === "1" && type === "CUSTOM")
  const rules = !validationCondition && record?.name === "English" ? [
    {
      validator: async (_, value) => {
        if (!value || value.trim() === '') {
          return Promise.reject(`Please Input ${title}!`);
        }
      },
    },
  ] : []
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
        >
          {inputNode}
        </Form.Item>
      ) : (
          children
        )}
    </td>
  );
};



const TranslationTable = (props) => {
  const {
    dataSource,
    handleTranslationChanges,
    resetData,
    showResetStatus,
    toggleReset,
    resetKey,
    selectedPurpose,
    editable,
    activeTab,
    readOnly,
  } = props;
  const [editingKey, setEditingKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentKey, setCurrentKey] = useState(null)
  const [currentRecord, setCurrentRecord] = useState({});
  const [currentData, setCurrentData] = useState(dataSource)
  const [previousData, setPreviousData] = useState(dataSource)
  const [showReset, setShowReset] = useState({})
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [keepTranslation, setkeepTranslation] = useState(true)
  const [visible, setVisible] = React.useState(false)
  const [form] = Form.useForm();
  const NO_TRANSLATION_TEXT = "No Translations found"

  const isEditing = (record) => record.key === editingKey;

  const handleRecordSave = async (record) => {
    const row = await form.getFieldValue();
    save(record)
  }

  const save = async (record) => {
    const { key } = record
    try {
      const row = await form.validateFields();
      const newData = [...dataSource]
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }

      setEditingKey('');
      setCurrentData(newData)
      handleTranslationChanges(newData)    
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const edit = (record) => {
    setCurrentRecord(record)
    form.setFieldsValue({
      name: '',
      translation: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Language',
      dataIndex: 'name',
      width: 200,
      key: 'name',
      className: 'language-name',
      render: (value, record) => <><span className="language">{value}{(record.translation === '' || record.translation === NO_TRANSLATION_TEXT) ? renderInfoTip(WARNING_TEXT, "warning") : null}</span></>
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      key: 'translation',
      editable: true,
      width: '70%',
      className: 'translation-column',
      render: (value) => {
        let fieldValue = value?.trim();
        if (fieldValue === '') {
          fieldValue = NO_TRANSLATION_TEXT;
          return <span className="no-translation">{fieldValue}</span>
        } else {
          return <span>{fieldValue?.replace(/\[|\]/g, "")}</span>
        }
      }
    },
  ]

  if (!readOnly) {
    columns.push({
      title: "",
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        if (record.name !== 'Default') {
          const editable = isEditing(record);
          return editable ? (
            <div className="icon-container">
              <Button icon={<CheckOutlined/>} type="link" onClick={() => handleRecordSave(record)}/>
              <Button icon={<CloseOutlined/>} type="link" onClick={cancel} />
            </div>
          ) : (
              <Tooltip title="Edit"><Button icon={<EditOutlined/>} type="link" onClick={() => edit(record)}/></Tooltip>
            )
        }
      },
    })
  }


  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        type: selectedPurpose?.type,
        activeTab: activeTab
      }),
    };
  });


  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={false}
          scroll={{
            y: props.onEditScreen ? 'calc(50vh - 70px)' : 'calc(50vh - 70px)',
          }}
        />
      </Form>
    </>
  )
}

export default TranslationTable