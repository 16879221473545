import React, { useContext, useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { useDebouncedSearch } from "../../../../../../hooks";
import { Button, Input, Select, Toggle, message } from "../../../../../../styleguide";
import { Map } from "immutable";
import { hasFeature } from "../../../../../utils";
import { getVendorLists as getTcfVls } from "../../../../../../actions/vendor_list_actions_v2"; 
import { getVendorLists as getCcpaVls } from "../../../../../../actions/vendor_list_actions_ccpa";
import { getVendorListAjax as getTcfVlFromId } from "../../../../../../api/consent_v2/vendor_list";
import { getVendorListAjax as getCcpaVlFromId } from "../../../../../../api/ccpa/vendor_list";
import { Spin } from "antd";
import { VendorList } from "../../../../../../records/vendor_list_records";
import { usPrivacyRegulationContext } from "../../../contexts";
import VendorsTable from "./VendorsTable";
import { IMPORT_HOOKS_FROM_EXISTIN_VL_TOOLTIP, renderInfoTip } from "../../../helper";
import { VendorWrapper } from "../../../../../../records/us_privacy_regulation_records";

const ImportVendorsFromVL = (props) => {
  const { vendorsIdsMap } = useContext(usPrivacyRegulationContext);

  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value'])) ?? {};
  const [searchValue, setSearchValue] = useState('');
  const [importHooksAsWell, setImportHooksAsWell] = useState(false);
  const [disableImport, setDisableImport] = useState(true);
  const [vlType, setVlType] = useState(false);
  const [selectedVlId, setSelectedVlId] = useState(null);
  const [selectedVlObj, setSelectedVlObj] = useState(new VendorList({}));
  const [vendorsFromSelectedVl, setVendorsFromSelectedVl] = useState([]);
  const [vendorTableFilters, setVendorTableFilters] = useState({});

  const [vlList, setVlList] = useState([]);
  const [vlLoading, setVlLoading] = useState(false);

  useEffect(()=>{
    props.setImportHooks(importHooksAsWell)
  },[importHooksAsWell]);

  useEffect(()=>{
    if(selectedVlId) {
      setDisableImport(false)
    } else {
      setDisableImport(true);
    }
    },[selectedVlId]);

  useEffect(()=>{ 
    setSelectedVlId(null)
  },[vlType]);

  useEffect(()=>{
    const vls = props.vendorLists?.toJS()
    if(vls){
      setVlList(vls.map(vl => ({label: vl.name, value: vl.id})))
    } else {
      setVlList([])
    }
  },[props.vendorLists]);

  let vlTypeOptions = [];
  if(hasFeature(currentUser, 'tcf_v2')){
    vlTypeOptions.push({value: 'tcf_v2', label: 'GDPR TCF'})
  }
  if(hasFeature(currentUser, 'non_iab_vl')){
    vlTypeOptions.push({value: 'non_iab_vl', label: 'GDPR Standard'})
  }
  if(hasFeature(currentUser, 'ccpa')){
    vlTypeOptions.push({value: 'ccpa', label: 'U.S. Privacy (legacy)'})
  }

  const handleVlTypeChange = (vlType) => {
    switch(vlType){
      case 'tcf_v2':
        props.getTcfVls();
        break;
      case 'non_iab_vl':
        props.getTcfVls(true);
        break;
      case 'ccpa':
        props.getCcpaVls();
        break;
    }
    setVlType(vlType)
  }

  const handleSelectedVlChange = (vl) => {
    setSelectedVlId(vl);
  }

  const handleImportVendors = () => {
    setVlLoading(true);
    switch(vlType){
      case 'tcf_v2':
      case 'non_iab_vl':
        getTcfVlFromId(selectedVlId, currentUser.accountId).then((resp) => {
          setSelectedVlObj(resp);
          setVlLoading(false);
          setDisableImport(true);
        }).catch((err) => {
          console.log("error",err)
        });
        break;
      case 'ccpa':
        getCcpaVlFromId(selectedVlId, currentUser.accountId).then((resp) => {
          setSelectedVlObj(resp)
          setVlLoading(false);
          setDisableImport(true);
        }).catch((err) => {
          console.log("error",err)
        });
        break;
      default:
        break;
    }
  }
  useEffect(()=>{
    return () => {
      setSelectedVlObj(new VendorList({}))
    }
  },[]);

  useEffect(()=>{
    const vendors = selectedVlObj.get('vendors')?.toJS()
    let vendorsNamesMap = {}
    if(vendors.length){
      vendorsNamesMap = vendors.reduce((map, item)=>{
        map[item.id] = item.name;
        return map;
      }, {});
    }
    let vendorsWrappers = selectedVlObj.get('vendorsWrappers')?.toJS();
    vendorsWrappers = vendorsWrappers.filter(vw => Object.keys(vendorsNamesMap).includes(vw.vendorId));
    vendorsWrappers = vendors.map(v => {
      let vw = vendorsWrappers.find(vw => vw.vendorId == v.id);
      if(vw){
        return vw;
      }else {
        return new VendorWrapper({vendorId: v.id}).toJS()
      }
    });

    vendorsWrappers = vendorsWrappers.map((v) => ({...v, ids: vendorsIdsMap[v.vendorId], name: vendorsNamesMap[v.vendorId] ?? ""}));

    const findKeyByCcpaId = (ccpaId) => {
      const entry = Object.entries(vendorsIdsMap).find(([key, value]) => value?.ccpaId === ccpaId);
      return entry ? entry[0] : null;
    };
    if(vlType === 'ccpa') {
      vendorsWrappers = vendorsWrappers.filter(v => findKeyByCcpaId(v.vendorId)).map(v => ({...v, vendorId : findKeyByCcpaId(v.vendorId)}))
    } else {
      vendorsWrappers = vendorsWrappers.filter(vw => vendorsIdsMap[vw.vendorId]);
    }
    setVendorsFromSelectedVl(vendorsWrappers)
  },[selectedVlObj])

  useEffect(() => {
    if(vendorsFromSelectedVl.length){
      let vendorsHashMap = props.vendorsHashMap
      vendorsFromSelectedVl?.forEach(vendor => {
        vendorsHashMap[vendor.vendorId] = props.categories.map(cat => cat.id ?? cat.privacyChoice);
      })
      props.setVendorsHashMap(vendorsHashMap);
      props.setVendorsFromImportedVl(vendorsFromSelectedVl);
    }
  }, [vendorsFromSelectedVl]);

  const dataSource = useMemo(() => {
    if(vendorsFromSelectedVl.length){
      let dataSource = vendorsFromSelectedVl/*.filter( vendor => !props.existingVendorIds.includes(vendor.id))*/
      dataSource = searchValue.trim().length ? dataSource?.filter(vendor => vendor.vendorId.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || vendor.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1) : dataSource
      return dataSource;
    }
    return [];
  },[searchValue, vendorsFromSelectedVl])

  //debouncing search for performance
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  const emptyTableTextCheck = vendorTableFilters.name || vendorTableFilters.categories || searchValue.trim().length;

  return  props.pendingRequestsMap.get('vendorLists') || vlLoading ? <Spin tip={"Loading"}/> :  (
    <>
      <div>
        <div className="vendor-management-table-header-bar import-from-vl">
          <div>
            <label htmlFor="import_from">Import From</label>
            <Select
              id="import_from"
              options={vlTypeOptions}
              value={vlType}
              onChange={handleVlTypeChange}
            />
          </div>
          <div>
            <label htmlFor="vendor_list">Vendor List</label>
            <Select
              id="vendor_list"
              options={vlType ? vlList : []}
              value={selectedVlId}
              onChange={handleSelectedVlChange}
              showSearch
              optionFilterProp={'label'}
            />
          </div>
          <Button type="primary" disabled={disableImport} onClick={handleImportVendors}>Import Vendors</Button>
        </div>
        <hr/>
        <div className="vendor-management-table-header-bar">
          <Input
            placeholder="Search vendor name or ID"
            type="search"
            onChange={({ target: { value } }) => {
              debouncedChangeHandler(value);
            }}
            suffix={<SearchOutlined />}
          />
          <div className="button-grp">
            <Toggle checked={importHooksAsWell} onChange={(value) => {setImportHooksAsWell(value)}} className="right-margin"/><span>Import the hooks configuration for vendors {renderInfoTip(IMPORT_HOOKS_FROM_EXISTIN_VL_TOOLTIP)}</span>  
          </div>
        </div>
        <div>
          <VendorsTable
            isAddSysVendorsMode
            isImport
            dataSource={dataSource}
            setSelectedVendors={props.setSelectedVendorIds}
            isLoading={vlLoading}
            categoryOptions={props.categories.map(cat => ({value: cat.id ?? cat.privacyChoice, label: cat.privacyChoice}))}
            vendorsHashMap={props.vendorsHashMap}
            updateInstantly={false}
            selectedVendors={props.selectedVendorIds}
            overlappingVendorIds={props.isOverlap ? props.overlappingVendorIds : []}
            setVendorTableFilters={setVendorTableFilters}
            emptyTableTextCheck={emptyTableTextCheck}
          />
        </div>
      </div>  
    </>
  );
}

const mapStateToProps = function (store) {
  return {
    vendorLists: store.vendorListState.getIn(['vendorLists', 'value']),
    vendorListError: store.vendorListState.getIn(['vendorLists', 'error']),
    pendingRequestsMap: Map({
      vendorLists: store.vendorListState.getIn(['vendorLists', 'pending']),
    }),
  };
};

export default connect(mapStateToProps, {
  getCcpaVls,
  getTcfVls
})(ImportVendorsFromVL);