import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  Input,
  Button,
  Alert,
  Layout,
} from 'antd';
import { Map } from 'immutable';
import { createAccount } from '../../actions/account_actions';
import { validateEmail } from '../utils';
import DropdownPagesHeader from '../common/DropdownPagesHeader';
import { updateAccount } from '../../api/admin_consent';

class CreateAccount extends React.Component {
  state = {
    email: '',
    accountName: '',
    name: '',
    createdUserCreds: null,
    emailValidationError: '',
  };

  handleInputChange = (e, prop) => {
    let stateUpdate = new Map();
    stateUpdate = stateUpdate.set(prop, e.target.value);
    this.setState(stateUpdate.toJS());
  }

  handleCreate = () => {
    this.props.createAccount(this.state).then((resp) => {
      updateAccount(resp.id, ['hide_property_groups']);
    });
  }

  render() {
    if (!this.props.currentUser) return null;

    if (this.props.currentUser.accountId !== 22) {
      browserHistory.replace('/');
    }

    if (this.props.createdUserCreds) {
      browserHistory.push('admin/admin_settings');
    }

    let alert;
    if (this.props.createdUserCredsError) {
      alert = (
        <Alert
          message={ this.props.createdUserCredsError }
          type="error"
          onClose={ () => this.setState({ emailValidationError: '' })}
        />
      );
    }

    let alert2;
    if (this.state.emailValidationError) {
      alert2 = (
        <Alert
          message={ this.state.emailValidationError }
          type="error"
          onClose={ () => this.setState({ emailValidationError: '' })}
        />
      );
    }

    return (
      <>
        <DropdownPagesHeader name='Create Account' />
        <Layout className='create-new-account-container'>
          <Layout.Content>
            <div className='update-user-container'>
            { alert }
            <label>Account Name
              <Input value={ this.state.accountName } onChange={ (e) => this.handleInputChange(e, 'accountName') } />
            </label>
            <label>Name
              <Input value={ this.state.name } onChange={ (e) => this.handleInputChange(e, 'name') } />
            </label>
            <label>Email
              <Input value={ this.state.email } onChange={ (e) => {
                if (validateEmail(e.target.value)) {
                  this.setState({ emailValidationError: ''});
                } else {
                  this.setState({ emailValidationError: 'Email must be valid'});
                }
                this.handleInputChange(e, 'email')
              }} />
            </label>
            <Button
              onClick={ this.handleCreate }
              type="primary"
              disabled={ !this.state.accountName.trim() || !this.state.name.trim() || !this.state.email.trim() || this.state.emailValidationError }
            >
              Create Account
            </Button>
          </div>
          </Layout.Content>
        </Layout>

      </>
    );
  }
}

const mapStateToProps = function (store){
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    createdUserCreds: store.accountState.getIn(['createdUserCreds', 'value']),
    createdUserCredsError: store.accountState.getIn(['createdUserCreds', 'error']),
  };
};

export default connect(
  mapStateToProps, {
    createAccount,
  },
)(CreateAccount);
