import {
  CloseOutlined,
  MenuOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Input, InputNumber, Row, Select, Space, Switch, Typography } from "antd";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CUSTOM_ACTIONS, generateAction } from "./helper";
import { capitalizeFirstLetterAndSplitUnderscore } from "../../utils";

function validateXPath(path) {
  try {
    document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
    return { valid: true };
  } catch (e) {
    console.log(e);
    return { valid: false};
  }
}

const renderActionField = (actionField) => {
  let fieldControl = null;
  switch (actionField) {
    case "duration":
      fieldControl =  <InputNumber placeholder="Enter Duration in Seconds" width={150}/>
      break;
    case "xpath":
      fieldControl =  <Input placeholder="Enter Xpath"/>
      break;
    case "name":
      fieldControl =  <Select placeholder="Select name" options={[{label: "Choices Saved", value: "Choices Saved"}, {label: "Generic", value: "Generic"}]}/>
      break;
    case "text":
      fieldControl =  <Input placeholder="Enter text"/>
      break;
    case "click_all":
      fieldControl =  <Switch/>
      break;
    case "action":
      const options = [
        {label: "Accept All", value: "accept_all"}, 
        {label: "Reject All", value: "reject_all"},
        {label: "Opt Out", value: "ccpa optout"},
      ]
      fieldControl = <Select placeholder="Select name" options={options}/>
      break;
  }

  return fieldControl;
};

const renderActionFormItem = (action, name) => {
  return Object.keys(action).map((field) => {
    const control = renderActionField(field);
    if (Boolean(control)) {
      let rules = [
        {
          required: true,
          message: `Please input ${field}`,
        },
      ]
      if(field == "xpath") {
        rules=[
          {
            validator(_, value) {
              const result = validateXPath(value);
              if (!(value && result.valid && value.includes("/"))) {
                return Promise.reject("Invalid XPath expression");
              } else if(!value) {
                return Promies.reject("Please input Xpath")
              }
              return Promise.resolve();
            }
          }
        ]
      }
      return (
        <Col span={6} style={{marginRight: 16}}>
        <Form.Item
          label={capitalizeFirstLetterAndSplitUnderscore(field)}
          name={[name, field]}
          layout="horizontal"
          validateDebounce={1000}
          rules={rules}
        >
          {renderActionField(field)}
        </Form.Item>
        </Col>
      );
    }
  });
};

const ActionsDragger = (props) => {
 
  function onDragEnd(result) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if(props.form.getFieldValue("actions")[destination.index].type == "wildcard") {
      return;
    }
  
    const updatedActions = Array.from(props.form.getFieldValue("actions"));
    const actionToMove = updatedActions.splice(source.index, 1);
    updatedActions.splice(destination.index, 0, actionToMove[0]);
    props.form.setFieldValue("actions", updatedActions)
  }
  
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{width: "100%", position: "relative"}}>
              <Form.List name="actions">
                {(fields, { add, remove, move }) => {
                  const actionsOptions = CUSTOM_ACTIONS.map(action => ({key: action, label: <Button type="text" style={{width: "100%"}} onClick={() => add(generateAction(action), action == "wildcard" ? 0 : fields.length)}>{capitalizeFirstLetterAndSplitUnderscore(action)}</Button>}))
                  return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => {
                      const actionObj = props.form.getFieldValue(["actions", name])
                      return (
                      <Draggable draggableId={`${index}`} index={index} isDragDisabled={actionObj.type == "wildcard"} >
                        {(provided) => (
                          <Row
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={key}
                            className="action-row"
                            align="middle"
                          >
                            <Col span={1}>
                              <Button type="text" disabled={actionObj.type == "wildcard"} icon={<MenuOutlined/>} {...provided.dragHandleProps} /> 
                            </Col>
                            <Col span={3}>
                              <Typography.Text strong>{capitalizeFirstLetterAndSplitUnderscore(actionObj.type)}</Typography.Text>
                            </Col>
                            {renderActionFormItem(actionObj, name)}
                            <Col style={{position: 'absolute', right: 0}}>
                              <Button type="text" icon={<CloseOutlined/>} onClick={() => remove(name)}/> 
                            </Col>     
                          </Row>
                        )}
                      </Draggable>
                    )})}
                    <Dropdown
                      trigger="click"
                      menu={{
                        items: actionsOptions,
                      }}
                      placement="top"
                      arrow={false}
                    >
                      <Button type="default" style={{position: 'absolute', top: '-70px', right: 0}}>Add Action</Button>
                    </Dropdown>
                  </>
                )}}
              </Form.List>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ActionsDragger;