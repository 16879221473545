import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useMemo } from "react";
import { Layout, Table, Avatar, Button, Typography, Tag, Menu, Flex, ConfigProvider, theme } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import VirtualList from 'rc-virtual-list';
import { SearchOutlined, EditOutlined, DeleteOutlined, SettingFilled } from "@ant-design/icons";
import { Input,Modal,message } from "../../../../styleguide"
import { useDebouncedSearch, useActions, usePrevious } from "../../../../hooks";
import AddEditConnectionModal from "./AddEditConnection"
import {
  getAllVendors,
  createVendorConnection,
  updateVendorConnection,
  deleteVendorConnection,
  vendorConnectionCreated
} from "../../../../actions/preferences_actions"
import Loading from "../../../common/Loading.js";
import { ConfirmationWrapper } from "../../helper.jsx";
import { VendorConnection } from "../../../../records/preferences_records.js";
import ConnectionCategoriesModal from "./ConnectionCategoriesModal.jsx";
import moment from "moment";
import { withConfigProvider } from "../../../../hooks";
import { Map, List } from "immutable";

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const IntegrationHub = forwardRef((props, ref) => {
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const dispatch = useDispatch();
  const [showAddEditConnectionModal, setShowAddEditConnectionModal] = useState(false)
  const [showConnectionCategoriesModal, setShowConnectionCategoriesModal] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null)
  const [content, setContent] = useState([])
  const [mode, setMode] = useState(null)
  const getSystemVendorsList = useActions(getAllVendors);
  const createVendorConnectionApi = useActions(createVendorConnection);
  const updateVendorConnectionApi = useActions(updateVendorConnection);
  const systemVendors = useSelector(state => state.preferencesState.getIn(['vendors', 'value']));
  const systemVendorsPending = useSelector(state => state.preferencesState.getIn(['vendors', 'pending']))
  const vendorConnectionsPending = useSelector(state => state.preferencesState.getIn(['vendorConnections', 'pending']))
  const vendorConnectionsSavePending = useSelector(state => state.preferencesState.get('savePending'))
  const vendorConnections = useSelector(state => state.preferencesState.getIn(['vendorConnections','value'])).sort((a, b) => moment(b.dateUpdated).diff(moment(a.dateUpdated)));
  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value']))
  const accountId = currentUser?.accountId;

  const isFirstTimeExecuted = useRef(true)

  const connectionsLinkToConfigsMap = useMemo(() => {
    let connectionMap = Map();
    props.configurationsList.forEach((configuration) => {
      const { id: configId , categories, name } = configuration;
      categories.forEach((category) => {
        category.vendorConnection.forEach((vendor) => {
          const connectionRef = vendor.get("connectionRef");
          if (!connectionMap.has(connectionRef)) {
            connectionMap = connectionMap.set(connectionRef, List([{configId, name}]));
          } else if(!connectionMap.get(connectionRef)?.map((conn) => conn.configId)?.includes(configId)) {
            connectionMap = connectionMap.update(connectionRef, (list) =>
              list.push({configId, name})
            );
          }
        });
      });
    });
    return connectionMap;
  }, [props.configurationsList]);

  useEffect(() => {
    getSystemVendorsList()
  }, [])

  useEffect(() => {
    if ((systemVendors && systemVendors.size > 0)) {
      // handleItemClick(systemVendors[0])
      setSelectedVendorId(systemVendors.get(0).get('id'));
      isFirstTimeExecuted.current = false
      // Additional logic can be added here if needed
    }
  }, [systemVendors]);

  useImperativeHandle(ref, () => ({
    handleNewConncetion : (mode) => {
      onAddEdit(mode)
    }
  }));

  const onAddEdit = (mode, item={}) => {
    setSelectedConnection(new VendorConnection(item))
    setShowAddEditConnectionModal(true)
    setMode(mode)
  }

  const openCategoriesModal = (vendorConnectionRecord) => {
    setSelectedConnection(new VendorConnection(vendorConnectionRecord));
    setShowConnectionCategoriesModal(true);
  }

  const onDelete = (connectionId) => {
    if(connectionsLinkToConfigsMap.get(connectionId)) {
      message.error(<Text> Cannot delete this Connection as it is linked to the following Preferences Configurations : <b>{connectionsLinkToConfigsMap.get(connectionId)?.map((conn) => conn.name)?.toJS()?.join(", ")}</b></Text>)
    } else {
      dispatch(deleteVendorConnection(accountId, connectionId)).then((resp) => {
        if (resp) {
          message.success(<>Connection was successfully deleted</>)
        }
      });
    }
  }

  const closeModal = () => {
    setShowAddEditConnectionModal(false)
    setSelectedConnection(null)
    setShowConnectionCategoriesModal(false)
  }

  const handleItemClick = (item) => {
    setSelectedVendorId(item.key)
  };

  const addVendorConnection = async(connectionObj, mode, continueToCategoriesModal = false) => {
    try {
      if (mode === "add") {
        dispatch(vendorConnectionCreated(connectionObj))
        // const resp = await createVendorConnectionApi(connectionObj?.toJS());
        // if (resp?.name) {
        message.success(<>Connection was successfully created</>)
        // isFirstTimeExecuted.current = true
        if(continueToCategoriesModal) {
          setShowConnectionCategoriesModal(true);
          setShowAddEditConnectionModal(false);
          setSelectedConnection(connectionObj);
        } else {
          closeModal();
        }
        // }
      } else {
        const { accountId, categories, name, connectionId } = connectionObj?.toJS();
        const resp = await updateVendorConnectionApi({accountId, categories, name, connectionId});
        if (resp?.name) {
          message.success(<>Connection was successfully updated</>)
          closeModal()
        }
      }
    } catch (error) {
      message.error(error);
    }
  }


  
  const vendorsList = systemVendors.map(vendor => {
    const icon = <Avatar shape="square" size={48} src={vendor.iconUrl}/>
    const label = <Flex vertical><Text strong>{vendor.name}</Text><Text>{vendorConnections?.filter(conect => conect.vendorId === vendor.id)?.size ?? 0} Connections</Text></Flex>
    return (
      {
        key: vendor.id,
        icon,
        label,
      }
    )
  })
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Connection categories',
      dataIndex: ['categories', 'id'],
      width: '30%',
      align: 'right',
      render: (_, record) => {
        const connectionCategoriesLength = record.categories.length 
        let tag = <Tag color="error">No Categories</Tag>
        if(connectionCategoriesLength) {
          tag = <Tag color="processing">{connectionCategoriesLength}</Tag>
        }
        return <>{tag}<Button type="link" icon={<SettingFilled/>} onClick={() => openCategoriesModal(record)}></Button></>
      }
    },
    {
      title: 'Actions',
      dataIndex: 'connectionId',
      width: '20%',
      align: 'right',
      render: (id, record) => (
        <>
          <Button icon={<EditOutlined/>} type="link" onClick={() => onAddEdit('edit', record)} />
          <ConfirmationWrapper
            actionDescription="delete this Connection"
            deleteHandler={() => onDelete(record.connectionId)}
            deleteBtn={<Button icon={<DeleteOutlined/>} type="link"/>}
          />
        </>
      )
    }
  ];

  const dataSource = vendorConnections?.filter(conect => conect.vendorId === selectedVendorId)?.toJS()

  const CustomTable = withConfigProvider(Table);

  return systemVendorsPending ? <Loading/> : (
    <>
      {
        vendorConnections?.size == 0 ? (
          <Flex justify="center" align="center" vertical={true} style={{height: 300}}>
            <Typography.Title level={3}> Start by adding your first connection</Typography.Title>
            <Button type="primary" onClick={() => onAddEdit("add")}>+ New Connection</Button>
          </Flex>
        ) :
        <Layout className={'integration-category-mapping-layout'}>
          <Sider theme="light" width={300}>
            <Layout>
              <Header theme="light"><Text strong>Integrations</Text></Header>
              <Content>
                <Menu
                  onClick={handleItemClick}
                  selectedKeys={[selectedVendorId]}
                  mode="vertical"
                  items={vendorsList}
                />
              </Content>
            </Layout>
          </Sider>
          <Content>
            <CustomTable
              loading={vendorConnectionsPending || vendorConnectionsSavePending || systemVendorsPending }
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              bordered={false}
              locale={{ emptyText: "No Connections" }}
              scroll={{y: 'calc(70vh - 65px)'}}
              componentTokens={{
                headerBorderRadius: 0
              }}
            />
          </Content>
        </Layout>
      }
      {showAddEditConnectionModal ?
        <AddEditConnectionModal
          accountId={accountId}
          selectedConnection={selectedConnection}
          showAddEditConnectionModal={showAddEditConnectionModal}
          closeModal={closeModal}
          mode={mode}
          addVendorConnection={addVendorConnection}
          venConnNamesList={vendorConnections.filter(vc => vc.connectionId !== selectedConnection.connectionId).map(vc => vc.name)}
          linkedPrefConfigs={connectionsLinkToConfigsMap.get(selectedConnection?.connectionId)}
        /> : null
      }
      {showConnectionCategoriesModal ? ( 
        <ConnectionCategoriesModal
          selectedConnection={selectedConnection}
          showConnectionCategoriesModal={showConnectionCategoriesModal}
          closeModal={closeModal}
          addVendorConnection={addVendorConnection}
          linkedPrefConfigs={connectionsLinkToConfigsMap.get(selectedConnection?.connectionId)}
        />
      ) : null
      }
    </>
  )
})

export default IntegrationHub;
