import { Alert, Button, Col, Flex, Form, Input, Layout, message, Modal, Radio, Row, Select, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../../assets/images/spt-logo.png";
import {
  getDiagnoseAccountWebsites,
  getDiagnoseConsentCategories,
  getDiagnoseProxyTools,
} from "../../../actions/diagnose_scan_setup";
import { ExclamationCircleOutlined} from "@ant-design/icons";
import { createAccountScan, getAccountScanDetails, updateAccountScan } from "../../../api/diagnose_dashboard/scan_setup";
import { CUSTOM_SCENARIOS_MAP, generateAction, getConsentTypeParent, getScanAndConsentType, getScenarioOptions, HOURS_UNIT_MAP } from "./helper";
import { getScanFrequency } from "./helper";
import moment from "moment";
import ActionsDragger from "./ActionDragger";

const { Text, Title } = Typography;

const frequencOptions = [
  { label: "One time", value: "one_time" },
  { label: "Recurring", value: "recurring" },
];

const intervalOptions = [ "Hours", "Days", "Weeks", "Months"]

const OTHER_ACTIONS_CONSENT_ID = "fb16e81d-b596-49bb-8653-46200f0c9d45"

const consentTypeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

function DiagnoseScanSetupEditorModal({ accountId, onClose, refreshAccountScans, editScanId, modalOpenType, successfulScan }) {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  const [scanDetails, setScanDetails] = useState(null);
  const [savePending, setSavePending] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(true);
  const [webSiteName, setWebsiteName] = useState(null);

  const scanSetupState = useSelector((store) => store.diagnoseScanSetupState);
  const currentUser = useSelector(state => state.accountState.getIn(['userDetails', 'value']));

  const dispatch = useDispatch();

  const title = modalOpenType=== "create" ? "Create New Scan" : modalOpenType=== "duplicate" ? "Duplicate Scan" : "Scan Details";
  const proxyTools = scanSetupState.getIn(["proxyTools", "value"]) || [];
  const consentCategories = scanSetupState.getIn(["consentCategories", "value"]) || [];
  const websitePages = scanSetupState.getIn(["websitePages", "value"]) || [];
  const pendingRequestMap = {
    proxyTools: scanSetupState.getIn(["proxyTools", "pending"]),
    consentCategories: scanSetupState.getIn(["consentCategories", "pending"]),
    websitePages: scanSetupState.getIn(["websitePages", "pending"]),
  }
  
  useEffect(() => {
    //add event listener to browser back button
    const handlePopState = (event) => {
      closeScanSetupModal()
      window.history.pushState(null, '', window.location.pathname + window.location.search);
    };

    window.addEventListener('popstate', handlePopState);
    window.history.pushState(null, '', window.location.pathname + window.location.search);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [showModalConfirm]);

  useEffect( () => {
    const getScanDets = async () => {
      setSavePending(true);
      const scanDetails = await getAccountScanDetails({
        accId: accountId,
        scan_id: editScanId,
      })
      setSavePending(false);
      if(scanDetails.scenario) {
        setScanDetails(scanDetails);
      }
    };

    try {
      dispatch(getDiagnoseConsentCategories({ accId: accountId }));
      dispatch(getDiagnoseProxyTools({ accId: accountId }));
      dispatch(getDiagnoseAccountWebsites({ accId: accountId }));

      if(editScanId){
        getScanDets();
      }
     
    } catch (error) {
      console.log(error);
    } finally {
    }
  }, [editScanId]);

  useEffect(() => {
    if(modalOpenType === "create") {
      form.setFieldsValue({
        scanFrequency: "one_time",
        intervalType: "Hours",
        intervalValue: 1,
        scan_type_binary: "all",
        actions: []
      })
    } else if (scanDetails && (modalOpenType === "edit" || modalOpenType === "duplicate")) {
       const { scenario, failure_data, scan_data } = scanDetails;
      
      const scanFrequency = getScanFrequency(scenario.scan_frequency);
      let path = "";
      let websiteUrl = null;
      const selectedWebsite = websitePages.find((web) => web.id === scenario.website.id);
      if(selectedWebsite) {
        websiteUrl = selectedWebsite.url;
        const completeUrl = scenario.url;
        if(websiteUrl) {
          const splitUrlArray = completeUrl.split(websiteUrl)
          path = splitUrlArray[1];
        }
      }

      form.setFieldsValue({
        website: scenario.website.id,
        url: websiteUrl ?? scenario.url,
        scanTool: scenario.proxies[0].tool,
        scanFrequency: scenario.scan_frequency == 32767 ? "one_time" : "recurring",
        intervalType: scanFrequency.unit,
        intervalValue: scanFrequency.value,
        region: scenario.proxies[0].region,
        zone: scenario.proxies[0].zone, 
        consent_type_parent: getConsentTypeParent(scenario?.consent_type?.id),
        scan_type_binary: scenario.scan_type.includes("_all") ? "all" : "custom",
        defaultDelay: scenario.default_delay,
        failureReason: failure_data?.reason,
        actions: scenario.actions,
        path: path
      });
      setWebsiteName(scenario.website.name);
    }
  }, [scanDetails, modalOpenType]);

  const handleValuesChange = (changedValues, allValues) => {
    if(changedValues.consent_type_parent){
      form.setFieldValue("custom_scenario", null);
      if(changedValues.consent_type_parent === OTHER_ACTIONS_CONSENT_ID){
        form.setFieldValue("scan_type_binary", "custom")
      }
    }
    
    if(changedValues.scan_type_binary === "all"){
      form.setFieldValue("actions",[])
    }

    if(changedValues.custom_scenario) {
      let actions = CUSTOM_SCENARIOS_MAP[changedValues.custom_scenario].map((action) => generateAction(action.type, action.defaultValues));
      form.setFieldValue("actions", actions);
    }

    if(changedValues?.website) {
      const selectedWebsite = websitePages.find((web) => web.id === changedValues.website);
      if(selectedWebsite) {
        form.setFieldValue("url", selectedWebsite.url);
        setWebsiteName(selectedWebsite.name)
      }
    }
  }
  const handleFieldChange = (changedFields, allFields)=>{
    setShowModalConfirm(false)
  }

  const handleSubmit = async (values) => {
    const {scan_type, consent_type} = getScanAndConsentType(values.scan_type_binary, values.consent_type_parent, consentCategories);
    const interval_value = values.scanFrequency === "one_time" ? 32767 :  values.intervalValue * HOURS_UNIT_MAP[values.intervalType] ;
    const payload = {
      website: values.website,
      page: "fb182887-501d-4845-a168-56ae7d6858fb",
      email: currentUser.email,
      consent_type: consent_type,
      url:  `${values.url}${values.path}`,
      proxies: [
          {
            tool: values.scanTool,
            region: values.region,
            residential: true,
            zone: values.zone,
          }
      ],
      default_delay: 30,
      scan_frequency: interval_value,
      actions: values.actions ?? [],
      scan_type: scan_type,
      use_display: false,
      path: values.path      
    }
    try {
      setSavePending(true);
      if(modalOpenType== "create" || modalOpenType== "duplicate") {
        const resp = await createAccountScan({accId:accountId, accountId: accountId},payload);
        if(resp?.id) {
          message.success('Scan created successfully!')
          refreshAccountScans();
          form.resetFields();
          onClose();
        }
      } else if (modalOpenType=== "edit") {
        const resp = await updateAccountScan({ accId: accountId, scan_id: editScanId }, payload);
        if(resp.status == "updated") {
          message.success('Scan updated successfully!');
          refreshAccountScans();
          form.resetFields();
          onClose();
        
        }
      }
    } catch (error) {
      message.error("FAILED TO CREATE SCAN")
      console.error("Failed to create scan:", error);
    } finally {
      setSavePending(false)
    }
  };

  const closeScanSetupModal = async () => {
    if(showModalConfirm){
      onClose();
    }else{
      const confirm = await modal.confirm({
        title: 'Save Changes',
          icon: <ExclamationCircleOutlined />,
          content: (
            <>
              <p>All the changes will be lost.</p>
              <p>Are you sure you want to exit?</p>
            </>
          ),
          okText: 'Confirm',
          cancelText: 'Continue Editing',
      });
      if(confirm){
        onClose();
      }else{
        return;
      }
    }
  };

  function formatDateTime(dateString) {
    return moment(dateString).format('MMMM DD, YYYY h:mm:ss A');
  }

  const consntCatName = consentCategories.find((cat) => form.getFieldValue("consent_type_parent") === cat.id)?.name;
  const scenarioOptions = getScenarioOptions(consntCatName);

  const scanType = Form.useWatch('scan_type_binary', form);
  const consentType = Form.useWatch('consent_type_parent', form);
  const scanTool = Form.useWatch('scanTool', form);
  const scanZone = Form.useWatch('zone', form);
  const scanFrequency = Form.useWatch('scanFrequency', form);

  const scanZoneOptions = proxyTools.find((proxyTool) => proxyTool.name === scanTool)?.zones?.map((zone) => ({label: zone.name, value: zone.name})) ?? []
  const regionOptions = proxyTools.find((proxyTool) => proxyTool.name === scanTool)?.zones?.filter((zone) => zone.name === scanZone)?.map(zone => zone.regions)?.flat()?.map((region) => ({label: region.title, value: region.name})) ?? []
  const websiteOptions = websitePages?.map((web) => ({label: web.url, value: web.id})) ?? [];
  
  let hideScanTypeControl = consentType === OTHER_ACTIONS_CONSENT_ID;

  return (
    <Modal
      className="fullscreen-modal scan-setup-form--modal"
      title={
        <div className="spt-main">
          <div className="spt-subnav">
            <img src={Logo} alt="" />
          </div>
        </div>
      }
      width={"100vw"}
      open={modalOpenType}
      confirmLoading={savePending}
      destroyOnClose={true}
      onCancel={closeScanSetupModal}
      okText={modalOpenType=== "create" || modalOpenType=== "duplicate" ? "Create Scan" : "Save Scan"}
      onOk={form.submit}
      okButtonProps={{
        disabled: form.getFieldsError().some(({ errors }) => errors.length),
        loading: savePending
      }}
    > 
      {savePending ? <Spin tip="Loading..." size="large" fullscreen/> : null}
      <Layout className="scan-setup-form--container">
          <Flex gap="middle" vertical>
            <Space direction="vertical" size="small">
              <Typography.Title level={4} style={{margin: 0}}>{title}</Typography.Title>
              {modalOpenType=== "edit" && (
                <Text>
                  Last updated {formatDateTime(scanDetails?.scan_data.edited_time)} 
                  {scanDetails?.scan_data.last_successful_scan_date && (
                    <> | last successful scan, {formatDateTime(scanDetails?.scan_data.last_successful_scan_date)}</>
                  )}
                </Text>
              )}
            </Space>
            {modalOpenType=== "edit" && scanDetails?.failure_data && successfulScan === false ? (
              <Alert
                message="Reason for failed scan"
                description={
                  <ul>
                    <li>Reason: {scanDetails?.failure_data?.reason}</li>
                    <li>Failed At: {new Date(scanDetails?.failure_data?.failed_at).toLocaleString()}</li>
                    <li>Region: {scanDetails?.failure_data?.region}</li>
                    <li>Zone: {scanDetails?.failure_data?.zone}</li>
                  </ul>
                }
                type="error"
                closable
                showIcon
              />
            ) : null} 
          </Flex>

          <Form
            name="scan-setup"
            className="scan-setup-form--form"
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            onValuesChange={handleValuesChange}
            onFieldsChange={handleFieldChange}
            requiredMark={false}
          >
            <Row gutter={[48,24]} justify="space-between">  
              <Col span={12}>
                <Form.Item 
                  label="URL" 
                  name='website'
                  rules={[{required: true}]}
                >
                  <Select
                    showSearch
                    virtual
                    allowClear={false}
                    menuItemSelectedIcon={null}
                    className={"spsg-select-multiple"}
                    popupClassName={"spsg-select-multiple-drodown"}
                    placeholder="Select URL"
                    options={websiteOptions}
                    notFoundContent={pendingRequestMap.websitePages ? <Flex justify="center"><Spin/></Flex> : "No scanDetails"}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
                {webSiteName? <Text type="secondary">Property: {webSiteName}</Text> : null}
                <Form.Item
                  name={"url"}
                  hidden={true}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  name="path"
                  label={
                    <>
                      Path
                      <span style={{ color: "#9E9E9E" }}> (optional)</span>
                    </>
                  }
                >
                  <Input placeholder="/example" />
                </Form.Item>
              </Col>
              <Col span={12} >
                <Form.Item 
                  label="Scan tool" 
                  name="scanTool"
                  rules={[{required: true}]}
                >
                  <Select
                    allowClear={false}
                    menuItemSelectedIcon={null}
                    className={"spsg-select-multiple"}
                    popupClassName={"spsg-select-multiple-drodown"}
                    placeholder="Select Scan Tool"
                    notFoundContent={pendingRequestMap.proxyTools ? <Flex justify="center"><Spin/></Flex> : "No scanDetails"}
                  >
                    {proxyTools.map((tool) => (
                      <Select.Option key={tool.id} value={tool.name}>
                        {tool.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
              <Col span={12}>
                <Form.Item 
                  label="Scan frequency" 
                  name="scanFrequency"
                  rules={[{required: true}]}
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="outline"
                    options={frequencOptions}
                  />
                </Form.Item>
              </Col>
              {scanFrequency === "recurring" ? (
                <Col span={12}>
                  <Form.Item
                    label="Interval between each scan"
                  >
                    <div className="interval-input-wrapper">
                      <Form.Item
                        name="intervalValue"
                        rules={[
                          { required: true, message: 'Interval value is required' },
                          { validator:  (_, value) => {
                            if (!value || value < 1) {
                              return Promise.reject(new Error('Cannot be less than 1'));
                            }
                            return Promise.resolve();
                          } }
                        ]}
                        noStyle
                      >
                        <Input
                          type="number"
                          className="interval-input"
                          min={1}
                        />
                      </Form.Item>
                      <Form.Item
                        name="intervalType"
                        rules={[{ required: true, message: 'Interval type is required' }]}
                        noStyle
                      >
                        <Radio.Group
                          optionType="button"
                          buttonStyle="outline"
                          options={intervalOptions.map((opt) => ({ label: opt, value: opt }))}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                </Col>
              ) : <Col span={12}></Col>}
              <Col span={12}>
                <Form.Item 
                  label="Region" 
                  name="zone"
                  dependencies={['scanTool']}
                  rules={[
                    ({ setFieldValue }) => ({
                      validator(_, value) {
                        if(!value) {
                          return Promise.reject(new Error('Please enter Region'));
                        }else if (scanZoneOptions.some((option) => option.value == value)) {
                          return Promise.resolve();
                        } else {
                          setFieldValue('zone', null)
                          return Promise.reject(new Error(`Please select a valid value for region`));
                        }
                      },
                    }),
                  ]}
                >
                  <Select
                    showSearch
                    virtual
                    allowClear={false}
                    menuItemSelectedIcon={null}
                    className={"spsg-select-multiple"}
                    popupClassName={"spsg-select-multiple-drodown"}
                    placeholder="Select Region"
                    options={scanZoneOptions}
                    notFoundContent={scanTool ? "No Data" : "Please Select a Scan tool first"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  label="Country / State" 
                  name="region"
                  dependencies={['zone']}
                  rules={[
                    ({ setFieldValue }) => ({
                      validator(_, value) {
                        if(!value) {
                          return Promise.reject(new Error(`Please enter Country / State`));
                        }else if (regionOptions.some((option) => option.value == value)) {
                          return Promise.resolve();
                        } else {
                          setFieldValue('region', null)
                          return Promise.reject(new Error(`Please select a Country/State from ${scanZone}`));
                        }
                      },
                    }),
                  ]}
                >
                  <Select
                    showSearch
                    virtual
                    allowClear={false}
                    menuItemSelectedIcon={null}
                    className={"spsg-select-multiple"}
                    popupClassName={"spsg-select-multiple-drodown"}
                    placeholder="Select Country/State"
                    options={regionOptions}
                    notFoundContent={scanTool ? scanZone ?  "No Data" : "Please select a Region first" : "Please select a Scan Tool first"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item 
                  label="Consent Categories" 
                  name="consent_type_parent"
                  rules={[{required: true}]}
                >
                  <Select
                    showSearch
                    virtual
                    allowClear={false}
                    menuItemSelectedIcon={null}
                    className={"spsg-select-multiple"}
                    popupClassName={"spsg-select-multiple-drodown"}
                    placeholder="Select Consent"
                    notFoundContent={pendingRequestMap.consentCategories ? <Flex justify="center"><Spin/></Flex> : "No scanDetails"}
                  >
                    {consentCategories.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
                <Col span={12}>
                <Form.Item 
                  label="Type" 
                  name="scan_type_binary"
                  rules={[{required: true}]}
                  hidden={hideScanTypeControl}
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="outline"
                    options={consentTypeOptions}
                  />
                </Form.Item>
                </Col>
              <Col span={24}>
                {scanType=== "custom" && consentType ? 
                <Flex vertical className="custom-scenario-container">
                  <Row>
                    <Col span={24}>
                      <Typography.Title level={5}>Custom Scenarios</Typography.Title> 
                    </Col>
                    <Col span={24}>
                    <Form.Item 
                      name="custom_scenario"
                    >
                      <Select placeholder="Select Custom Scenario" options={scenarioOptions.map((opt) => ({value: opt, label: opt}))}/>
                    </Form.Item>
                    </Col>
                  </Row>
                  <ActionsDragger form={form}/>
                </Flex> : null}
              </Col>
            </Row>
          </Form>
       {contextHolder}
      </Layout>
    </Modal>
  );
}

export default DiagnoseScanSetupEditorModal;