export const preferences_steps = [
  {
    label: "Name and Properties",
    title: "Name and Properties",
    status: "process",
    key: "name_properties",
    warning: false,
    error: false,
  },
  {
    label: "Categories",
    steps: [
      {
        title: "Add Categories",
        status: "wait",
        key: "add_categories",
        warning: false,
        error: false,
      },
      {
        title: "Language and Translations",
        status: "wait",
        key: "language_translations",
        warning: false,
        error: false,
      },
    ],
  },
  {
    title: "Category Mapping",
    label: "Category Mapping",
    status: "wait",
    key: "category_mapping",
    warning: false,
    error: false,
  },
  {
    label: "Summary",
    title: "Summary",
    status: "wait",
    key: "summary",
    warning: false,
    error: false,
  },
]

export const evaluateRequriedFieldsFromStep = (
  current,
  intermediateUSPRegulation,
  regulationListNames
) => {
  let isStepcomplete = true;
  switch (current) {
    case "name":
      if (
        intermediateUSPRegulation.name.trim().length == 0 ||
        regulationListNames.includes(intermediateUSPRegulation.name.trim())
      ) {
        isStepcomplete = false; 
      }
      break;
    case "properties":
      if (
        intermediateUSPRegulation.propertyIds.length == 0
      ) {
        isStepcomplete = false;
      }
      break;
    case "name_properties":
      if (
        intermediateUSPRegulation.name.trim().length == 0 ||
        regulationListNames.includes(intermediateUSPRegulation.name.trim()) ||
        intermediateUSPRegulation.propertyIds.length == 0
      ) {
        isStepcomplete = false;
      }
      break;
    case "add_categories":
      if (intermediateUSPRegulation.categories.filter(cat => !Boolean(cat.dateDeleted)).length == 0) {
        isStepcomplete = false;
      }
      break;
    case "language_translations":
      // const categories = intermediateUSPRegulation.categories.filter(cat => cat.type === "CUSTOM");
      // let emptyTranslations = categories.length > 0 ? categories.filter(cat => {
      //   let translations = cat.isNegative ?  cat?.negativeTranslations : cat?.translations;
      //   return translations === null || translations?.names["en"] === "No Translations found" || translations?.names["en"] === ""
      // }): [];
      // if (emptyTranslations.length > 0) {
      //   isStepcomplete = false;
      // }
      break;
    case "category_mapping":
      if(!intermediateUSPRegulation.categories.some((cat) => !cat.dateDeleted && cat.vendorConnection.length)){
        isStepcomplete = false;
      }
      break;
    case "summary":
      break;
  }
  return isStepcomplete;
};

export const valideAllStepsBeforeSave = (steps, intermediateUSPRegulation, regulationsList) => {
  let stepsWithError = [];
  steps.forEach(step => {
    if(!evaluateRequriedFieldsFromStep(step, intermediateUSPRegulation, regulationsList)){
      stepsWithError.push(step)
    }
  })
  return stepsWithError;
}
