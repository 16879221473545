import React from 'react';
import { DeleteFilled , EditFilled, CheckSquareFilled, CloseSquareFilled, PlusCircleFilled} from '@ant-design/icons';
import { List, fromJS } from 'immutable';

import { ConsentFilters } from '../../../records/ccpa_dnsa_reporting_records.js';

import { trimString } from "../../consent/gdpr_performance_tcfv2_v2/helper";

import {
  BROWSERS_LIST,
  DEVICES_LIST,
  OS_FAMILY_LIST,
  GEO_FILTER_LIST_DETAILS as GEO_LIST,
  US_STATES
} from '../../../constants';
import { SelectDropdown, Chip, Input } from '../../../styleguide/index.js';

let newConsentFilters;
const browsers = BROWSERS_LIST;
const devices = DEVICES_LIST;
const os = OS_FAMILY_LIST;
const GEO_FILTER_LIST_DETAILS = new List([...GEO_LIST.filterNot(c => c.type === 'state' || c.type == 'province'),...US_STATES]);
const regionsAndCountries = GEO_FILTER_LIST_DETAILS.filter(
  (c) => c.type == 'country' || c.type == 'region'
);
const regionsAndCountriesCodes = regionsAndCountries.map((c) =>
  c.type == 'region' ? 'region:' + c.code : c.code
);
const countries = GEO_FILTER_LIST_DETAILS.filter(
  (c) => c.type == 'country'
);
const countriesCodes = countries.map((c) => c.code);
const states = GEO_FILTER_LIST_DETAILS.filter((c) => c.type == 'state');
const regionsAndCountriesAll = GEO_FILTER_LIST_DETAILS.map((g) =>
  g.type == 'region'
    ? 'region:' + g.code
    : g.type == 'state'
    ? 'state:' + g.code
    : g.code
);
const regionValueList = List(['region:EEA', 'region:NA', 'region:OTHER']);
const regions = List(['EEA', 'NA']);

export default class CustomCriteriaSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    const newConsentFiltersRecord = this.initialiseConsentFilter();
    this.state = {
      consentFilters: newConsentFiltersRecord,
      datePicker: 'WEEK',
      selectedSite: 'Select a property',
      criteriaList: List(),
      siteId: List(),
      geo: props.showState ? regionsAndCountriesAll : regionsAndCountriesCodes,
      device: devices,
      browser: browsers,
      os: os,
      siteGroupsSelected: List([]),
      prevSelectedRegions: regionValueList,
      prevSelectedSites: List([]),
      prevSelectedCountries: countriesCodes,
    };
  }

  initialiseConsentFilter = () =>
    new ConsentFilters({
      startDate: this.props.rangeSelect.get('startDate'),
      endDate: this.props.rangeSelect.get('endDate'),
      geo: countriesCodes.toJS(),
      device: devices.toJS(),
      browser: browsers.toJS(),
      os: os.toJS(),
      state: this.props.showState ? states.map(s => s.code) : List([]),
    });

  updateConsentState = (updatedConsentFilters) => {
    this.setState({ consentFilters: updatedConsentFilters });
  };

  getSiteGroupSiteIds = (id) => {
    return this.props.siteGroups.find((sg) => sg.siteId === id).siteIds;
  };
  getSitesWithoutSiteGroups = (sites) => {
    //XXX not filtering for all sites
    return sites.filterNot((s) => s.type === 'property_group');
  };
  getCountryCodesByRegion = (region) => {
    //filtering for all countries from list
    const chkForOtherRegion = region === 'OTHER';
    return countries
      .filter((c) => {
        return List.isList(c.region)
          ? c.region.includes(region)
          : ( chkForOtherRegion ? !regions.includes(c.region) : c.region == region);
      })
      .map((cc) => cc.code);
  };

  // For now California is selectable specific to CCPA only
  checkForCCPAMandateStatesInclusion = (regionsSelected, resultSiteIds) =>
    (!this.state.prevSelectedRegions.includes('region:NA') && regionsSelected.some((id) => id === 'region:NA')) ||
    (!this.state.prevSelectedCountries.includes('US') &&  resultSiteIds.some((id) => id === 'US'));

  handleSelectAll = (setArrKey, value) => {
    newConsentFilters = this.state.consentFilters;
    let siteGroupsSelected = List([]);
    let regionsSelected = List([]);
    let statesSelected = List([]);
    let resultSiteIds;
    let sites = this.props.sites;
    sites = this.getSitesWithoutSiteGroups(sites);
    const checkForAll = value.some((id) => id === 'All');
    if (setArrKey == 'siteId' && !checkForAll) {
      resultSiteIds = value
        .map((id) => {
          if (id.slice(0, 11) === 'site-group:') {
            siteGroupsSelected = siteGroupsSelected.push(id);
            return this.getSiteGroupSiteIds(Number(id.slice(11)));
          } else {
            return id;
          }
        })
        .flatten(true)
        .map(String)
        .toSet()
        .toList();
      this.state.siteGroupsSelected.forEach((sg) => {
        if (!siteGroupsSelected.includes(sg)) {
          resultSiteIds = resultSiteIds.filterNot((id) =>
            this.getSiteGroupSiteIds(Number(sg.slice(11))).includes(Number(id))
          );
        }
      });
      this.state.prevSelectedSites.forEach((ss) => {
        if (!value.includes(ss)) {
          let index = resultSiteIds.findIndex((item) => item == ss);
          if(index != -1) resultSiteIds = resultSiteIds.delete(index);
          this.props.siteGroups.map((sg) => {
            if (sg.siteIds.includes(Number(ss))) {
              let ind = siteGroupsSelected.findIndex((item) => {
                return item == 'site-group:' + sg.siteId;
              });
              siteGroupsSelected = siteGroupsSelected.delete(ind);
            }
          });
        }
      });
      value = siteGroupsSelected
        .toSet()
        .union(resultSiteIds.toSet())
        .toList();
    } else if (setArrKey == 'geo' && !checkForAll) {
      resultSiteIds = value
        .map((c) => {
          if (c.slice(0, 7) === 'region:') {
            regionsSelected = regionsSelected.push(c);
            return this.getCountryCodesByRegion(c.slice(7));
          } else {
            return c;
          }
        })
        .flatten(true)
        .map(String)
        .toSet()
        .toList();

      //Removing countries under region, if region is de-selected
      let flagNA = false; // flag for checking de-selection of N.America
      this.state.prevSelectedRegions
        .filter((r) => !regionsSelected.includes(r))
        .forEach((r) => {
          resultSiteIds = resultSiteIds.filterNot((c) =>
            this.getCountryCodesByRegion(r.slice(7)).includes(c)
          );
          if (r == 'region:NA') {
            flagNA = true;
            resultSiteIds = resultSiteIds.filterNot((c) => c.indexOf('state:') === 0)
          }
        });
      let flagUS = false; // flag for checking de-selection of United States
      this.state.prevSelectedCountries
        .filter((psc) => !value.includes(psc))
        .forEach((ss) => {
          let index = resultSiteIds.findIndex((item) => item == ss);
          resultSiteIds = resultSiteIds.delete(index);
          // de-selecting region if one of the country is missing
          this.getRegionNames(regionsAndCountries).map((sg) => {
            if (this.getCountryCodesByRegion(sg.code).includes(ss)) {
              let ind = regionsSelected.findIndex(
                (item) => item == 'region:' + sg.code
              );
              regionsSelected = regionsSelected.delete(ind);
            }
          });
          if (ss == 'US') {
            flagUS = true;
            resultSiteIds = resultSiteIds.filterNot((c) => c.indexOf('state:') === 0)
          }
        });
      // if California in region showing up in region dropdown
      if (this.props.showState) {
        const chkForCASelected =
          this.checkForCCPAMandateStatesInclusion(
            regionsSelected,
            resultSiteIds
          );
        statesSelected =
          chkForCASelected && !flagNA && !flagUS
            ? states.map(s => `state:${s.code}`)
            : statesSelected;
        newConsentFilters =
          chkForCASelected && !flagNA && !flagUS
            ? this.state.consentFilters.set('state', states.map(s => s.code))
            : this.state.consentFilters.set('state', resultSiteIds.filter((si) => si.slice(0, 6) === 'state:').map(s => s.slice(6)));
      }
      value = regionsSelected
        .toSet()
        .union(resultSiteIds.toSet(), statesSelected.toSet())
        .toList();
    }

    let valueList;
    const ddObj = {
      geo: this.props.showState
        ? regionsAndCountriesAll
        : regionsAndCountriesCodes,
      device: devices,
      browser: browsers,
      os: os,
      siteId: sites
        .map((s) => s.id.toString())
        .concat(this.props.siteGroups.map((sg) => 'site-group:' + sg.siteId)),
    };

    if (value && value.size && checkForAll) {
      if (value.size === ddObj[setArrKey].size + 1) {
        valueList = List();
      } else {
        if (setArrKey == 'siteId') {
          siteGroupsSelected = this.props.siteGroups.map(
            (sg) => 'site-group:' + sg.siteId.toString()
          );
        }
        valueList = ddObj[setArrKey];
      }
    } else {
      valueList = value;
    }
    if(setArrKey == 'geo') {
      // if all countries of region are selected, auto select region
      regionsSelected = regionValueList.filter((r, ri) => {
        const regionWiseCountries = this.getCountryCodesByRegion(r.slice(7));
        const isAllCountriesSelected = regionWiseCountries.every(val => valueList.includes(val));
        return isAllCountriesSelected;
      });
      valueList = valueList.toSet().union(regionsSelected.toSet()).toList();
    }
    this.setState(
      {
        [setArrKey]: valueList,
        siteGroupsSelected,
        prevSelectedRegions:
          setArrKey == 'geo' ? regionsSelected : this.state.prevSelectedRegions,
        consentFilters: newConsentFilters,
      },
      () => {
        if (setArrKey == 'siteId') {
          valueList = valueList.filterNot(
            (sg) => sg.slice(0, 11) === 'site-group:'
          );
          this.setState({ prevSelectedSites: valueList });
        }
        if (setArrKey == 'geo') {
          valueList = valueList
            .filterNot((sg) => sg.slice(0, 7) === 'region:')
            .filterNot((sg) => sg.slice(0, 6) === 'state:');
          this.setState({ prevSelectedCountries: valueList });
        }
        newConsentFilters = this.state.consentFilters.set(
          setArrKey,
          valueList.toJS()
        );
        this.updateConsentState(newConsentFilters);
      }
    );
  };
  addCriteria = () => {
    if (
      this.state.criteriaList.size < 3 &&
      this.state.consentFilters &&
      (this.props.showState
        ? this.state.consentFilters.get('state').size > 0 ||
          this.state.consentFilters.get('geo').length > 0
        : this.state.consentFilters.get('geo').length > 0) &&
      this.state.consentFilters.get('siteId').length > 0
    ) {
      let list = this.state.criteriaList.push(this.state.consentFilters);
      list = list.map((c) =>
        c
          .set('startDate', this.props.rangeSelect.get('startDate'))
          .set('endDate', this.props.rangeSelect.get('endDate'))
      );
      const newConsentFiltersRecord = this.initialiseConsentFilter();
      this.setState(
        {
          criteriaList: list,
          siteId: List(),
          geo: this.props.showState
            ? regionsAndCountriesAll
            : regionsAndCountriesCodes,
          device: devices,
          browser: browsers,
          os: os,
          consentFilters: newConsentFiltersRecord,
          siteGroupsSelected: List([]),
          prevSelectedSites: List([]),
          prevSelectedCountries: countriesCodes,
          prevSelectedRegions: regionValueList,
        },
        () => this.props.updateCriteriaListCb(this.state.criteriaList)
      );
    }
  };

  capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  removeCriteria = (index) => {
    let list = this.state.criteriaList.delete(index);
    this.setState(
      {
        criteriaList: list
      },
      () => this.props.updateCriteriaListCb(this.state.criteriaList)
    );
  };

  getPropertyName = (siteId) => {
    let index = this.props.sites.findIndex((item) => {
      return item.get('id') === Number(siteId);
    });
    let value = this.props.sites.getIn([index, 'domain']);
    return value;
  };

  getStateName = (stateId) => states.find((c) => c.code === stateId).name || '';

  removetag(e, criteriaIndex, elementIndex, arrName) {
    e.preventDefault();
    let list = this.state.criteriaList;
    let arr = this.state.criteriaList.get(criteriaIndex).get(arrName);
    let updatedArr = arr.filter((e, i) => i !== elementIndex);
    list = list.setIn([criteriaIndex, arrName], updatedArr);
    this.setState(
      {
        criteriaList: list,
      },
      () => this.props.updateCriteriaListCb(this.state.criteriaList)
    );
  }

  preventDefault(e) {
    e.preventDefault();
  }
  getCountryNames = (countries) => {
    //filtering for all countries from list
    return countries.filter((c) => c.type === 'country');
  };
  getRegionNames = (regions) => {
    //filtering for all regions from list
    return regions.filter((r) => r.type === 'region');
  };
  getStateNames = (states) => {
    //filtering for all states from list
    return states.filter((s) => s.type === 'state');
  };
  getCountryNameByCode(code) {
    return regionsAndCountries.find((c) => c.code === code).name;
  }
  toggleViewMore = (criteriaId) => {
    this.setState((prevState) => ({ [criteriaId]: !prevState[criteriaId] }));
  }

  render() {
    let sites = this.props.sites;
    sites = this.getSitesWithoutSiteGroups(sites);

    let sitesOptions = sites.map((site) =>({label: site.domain, value: site.id.toString()})).toJS();
    let siteGroupOptions = this.props.siteGroups.map((siteGroup) =>({label: siteGroup.name, value: 'site-group:' + siteGroup.siteId})).toJS();

    let sitesAndGroupsOptions = [
      {
        label: "Properties",
        options: sitesOptions
      }
    ]

    if (!this.props.accountFeatures.includes('hide_property_groups')) {
      sitesAndGroupsOptions.unshift(
        {
          label: "Property Groups",
          options: siteGroupOptions
        }
      )
    }

    let countriesOptions = this.getCountryNames(regionsAndCountries).map((country) =>({label: country.name, value: country.code})).toJS()
    let regionsOptions = this.getRegionNames(regionsAndCountries).map((region) =>({label: region.name, value: 'region:' + region.code})).toJS()
    let statesOptions = this.props.showState ? (
        [{
          label: 'State',
          options: states.map((state) =>({label: state.name, value: 'state:' + state.code})).toJS()
        }]
    ) : null;

    let regionsAndCountriesOptions = [
      ...(this.props.showState ? statesOptions : []),
      {
        label: "Regions",
        options: regionsOptions,
      },
      {
        label: "Countries",
        options: countriesOptions,
      },
    ];
    let browsersOptions = browsers.map((browser, i) => ({
      label: this.capitalize(browser),
      value: browser,
    }));
    let devicesOptions = devices.map((device, i) => ({
      label: this.capitalize(device),
      value: device,
    }));
    let osOptions = os.map((os, i) => ({
      label: this.capitalize(os),
      value: os,
    }));
    return (
      <div className="consent-performance-v2">
        {this.props.showTopLevelCriteria ? (
          <div className="adblock">
            <div className="select-multi-container">
              <div className="multiselect-sites-container">
                <span className="multi-select-heading">Properties</span>
                <SelectDropdown
                    options={sitesAndGroupsOptions}
                    onChange={(value) =>
                      this.handleSelectAll('siteId', fromJS(value))
                    }
                    value={this.state.siteId.toJS()}
                    selectAll
                    multiple
                    entityName="Properties"
                    groupOptions
                    showSearch
                    directUpdate
                  />
                <span className="note">Choose one or more properties</span>
              </div>
              <div className="rightAligned">
                <div className="multiselect-rightaligned-container">
                  <span className="multi-select-heading">Regions</span>
                  <SelectDropdown
                    options={regionsAndCountriesOptions}
                    onChange={(value) =>
                      this.handleSelectAll('geo', fromJS(value))
                    }
                    value={this.state.geo.toJS()}
                    selectAll
                    multiple
                    entityName="Regions"
                    groupOptions
                    showSearch
                    directUpdate
                  />
                </div>

                <div className="multiselect-rightaligned-container">
                  <span className="multi-select-heading">Devices</span>
                  <SelectDropdown
                    options={devicesOptions.toJS()}
                    onChange={(value) =>
                      this.handleSelectAll('device', fromJS(value))
                    }
                    value={this.state.device.toJS()}
                    selectAll
                    multiple
                    entityName="Devices"
                    showSearch
                    directUpdate
                  />
                </div>

                <div className="multiselect-rightaligned-container">
                  <span className="multi-select-heading">Browsers</span>
                  <SelectDropdown
                    options={browsersOptions.toJS()}
                    onChange={(value) =>
                      this.handleSelectAll('browser', fromJS(value))
                    }
                    value={this.state.browser.toJS()}
                    selectAll
                    multiple
                    entityName="Browsers"
                    showSearch
                    directUpdate
                  />
                </div>

                <div className="multiselect-rightaligned-container">
                  <span className="multi-select-heading">OS Family</span>
                  <SelectDropdown
                    options={osOptions.toJS()}
                    onChange={(value) =>
                      this.handleSelectAll('os', fromJS(value))
                    }
                    value={this.state.os.toJS()}
                    selectAll
                    multiple
                    entityName="OS Families"
                    showSearch
                    directUpdate
                  />
                </div>

                <div className="add-criteria">
                  <PlusCircleFilled onClick={this.addCriteria}/>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {this.props.showTopLevelCriteria &&
          this.state.criteriaList.map((c, i) => {
            return (
              <div className="criteria-container" key={i}>
                {
                  this.props.criteriaNames && this.props.criteriaNames.size ? 
                        (this.props.criteriaEditId === i ? 
                            <div className="editable-criteria-title">
                              <Input className="critera-input" placeholder={`Criteria ${i+1}`} value={this.props.criteriaName} onChange={(e) => this.props.handleEditCriteriaName(e.target.value)}/>
                              <CheckSquareFilled onClick={() => this.props.handleConfirmCriteriaEdit()}/>
                              <CloseSquareFilled onClick={() => this.props.dismissEdit()}/>
                            </div>
                        : 
                        <div className="editable-criteria-title">
                           {trimString(this.props.criteriaNames.get(i), 20)} 
                           <EditFilled onClick={()=>this.props.editCriteria(i)}/>
                        </div>) 
                    : 
                    <div className="editable-criteria-title">Criteria {i+1}</div>
                }
                <div className="criteria-tags-container">
                  {c.get('siteId').length == 1 ? (
                    <Chip >
                      {this.getPropertyName(c.get('siteId')[0])}
                    </Chip>
                  ) : (
                    c.get('siteId').filter((s, si) => this.state['vm_properties-'+i] ? true : [0, 1].includes(si)).map((s, si) => (
                      <Chip
                        key={si}
                        
                        closable
                        onClose={(e) =>
                          c.get('siteId').length == 1
                            ? this.preventDefault(e)
                            : this.removetag(e, i, si, 'siteId')
                        }
                      >
                        {this.getPropertyName(s)}
                      </Chip>
                    ))
                  )}
                  {c.get('siteId').length > 2 ? <span className='moreless' onClick={() => this.toggleViewMore('vm_properties-'+i)}>{this.state['vm_properties-'+i] ? '...Less' : 'More...'}</span> : null}
                  {c.get('state').filter((s, si) => this.state['vm_states-'+i] ? true : [0, 1].includes(si)).map((st) => (
                    <Chip
                      key={st}
                      
                      closable
                      onClose={(e) => this.removetag(e, i, 0, 'state')}
                    >
                      {this.getStateName(st)}
                    </Chip>
                  ))}
                  {c.get('state').size > 2 ? <span className='moreless' onClick={() => this.toggleViewMore('vm_states-'+i)}>{this.state['vm_states-'+i] ? '...Less' : 'More...'}</span> : null}
                  {c.get('geo').length == 1 ? (
                    <Chip >
                      {this.getCountryNameByCode(c.get('geo')[0])}
                    </Chip>
                  ) : this.getCountryNames(regionsAndCountries).size ==
                    c.get('geo').length ? (
                    <Chip >All Regions</Chip>
                  ) : (
                    c.get('geo').map((g, gi) => (
                      <Chip
                        key={gi}
                        
                        closable
                        onClose={(e) =>
                          c.get('geo').length == 1
                            ? this.preventDefault(e)
                            : this.removetag(e, i, gi, 'geo')
                        }
                      >
                        {this.getCountryNameByCode(g)}
                      </Chip>
                    ))
                  )}
                  {devices.size == c.get('device').length ? (
                    <Chip >All Devices</Chip>
                  ) : (
                    c.get('device').map((d, di) => (
                      <Chip
                        key={di}
                        
                        closable
                        onClose={(e) => this.removetag(e, i, di, 'device')}
                      >
                        {this.capitalize(d)}
                      </Chip>
                    ))
                  )}
                  {browsers.size == c.get('browser').length ? (
                    <Chip >All Browsers</Chip>
                  ) : (
                    c.get('browser').map((b, bi) => (
                      <Chip
                        key={bi}
                        
                        closable
                        onClose={(e) => this.removetag(e, i, bi, 'browser')}
                      >
                        {b}
                      </Chip>
                    ))
                  )}
                  {os.size == c.get('os').length ? (
                    <Chip >All OS Families</Chip>
                  ) : (
                    c.get('os').map((o, oi) => (
                      <Chip
                        key={oi}
                        
                        closable
                        onClose={(e) => this.removetag(e, i, oi, 'os')}
                      >
                        {o}
                      </Chip>
                    ))
                  )}
                </div>
                <div
                  className="remove-criteria"
                  onClick={() => this.removeCriteria(i)}
                >
                  <DeleteFilled />
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}
