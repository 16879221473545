import React, { useState,forwardRef, useEffect, useImperativeHandle } from 'react';
import { Table, Button, Input, Form, Badge, Typography, Space } from 'antd';
import { MinusCircleOutlined, EditOutlined, CheckOutlined,CloseOutlined} from "@ant-design/icons";
import { theme } from 'antd';

const ConnectionTable = forwardRef((props, ref) => {
  const [dataSource, setDataSource] = useState(props?.categories?.toJS());
  const [editingKey, setEditingKey] = useState(null);
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  useEffect(() => {
    let categories = props?.categories?.map((obj, index) => ({
      ...obj,
      key: obj?.id // You can customize the key generation logic as needed
    }));
    setDataSource(categories?.toJS())
    setEditingKey(null);
  }, [props?.categories])

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ name: '', id: '', ...record });
    setEditingKey(record.key);
  };

  const cancel = (record) => {
    if (record.id === '') {
      // If cancelling a new record, remove it from the data source
      const newData = dataSource.filter(item => item.key !== record?.key);
      setDataSource(newData);
    } else {
      // If cancelling an existing record, restore the original data
      const newData = [...dataSource];
      const index = newData.findIndex(item => item.key === record?.key);
      if (index > -1) {
        newData[index] = record;
        setDataSource(newData);
      }
    }
    setEditingKey(null);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        setEditingKey(null);
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey(null);
      }
      props.handleCategories(newData)
    } catch (errInfo) {
    }
  };

  useImperativeHandle(ref, () => ({
    addNewCategory : () => {
      add();
    }
  }));

  const add = () => {
    if (editingKey) {
      form.validateFields();
    } else {
      const newData = {
        key: Date.now(),
        id: '',
        name: '',
      };
      setDataSource([newData, ...dataSource]);
      edit(newData);
    }
  };

  const deleteRecord = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    props.handleCategories(newData)
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      editable: true,
      width:'20%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      width: '60%',
      render: (name, record) => {
        let icon = null;
        if(record.newlyAdded) {
          icon = <Badge key={record.id} color={token.colorPrimary}/>
        }
        return (
            <Typography.Text><Space>{icon}{name}</Space></Typography.Text>
        )
      }
    },
    {
      title: "Actions",
      dataIndex: 'action',
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button type='link' icon={<CheckOutlined/>} onClick={() => save(record.key)}/>
            <Button type='link' icon={<CloseOutlined/>} onClick={() => cancel(record)}/>
          </>
        ) : (
          <>
            {record.isPulledFromVendor ? null : <Button type='link' icon={<EditOutlined/>}  disabled={editingKey}  onClick={() => edit(record)}/>}
            <Button type='link' icon={<MinusCircleOutlined/>}onClick={() => deleteRecord(record.key)}/>
          </>
          );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <Input placeholder={`Enter ${title}`} />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                validator: (rule, value) => {
                  if(rule.field === "id" && props.categories.filter(cat => cat.id !== editingKey).map(cat => cat.id).includes(value)) { 
                    return Promise.reject("Duplicate ID")
                  } else if(value.trim() == "") {
                    return Promise.reject(`Please enter ${title}`)
                  } else {
                    return Promise.resolve();
                  }
                },
                validateTrigger: "onSave"
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  };

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        scroll={{y: 200}}
        bordered
        dataSource={dataSource}
        columns={mergedColumns}
        rowClassName="editable-row"
        className="connection-table"
        pagination={false}
        locale={{ emptyText: 'No Categories' }}
      />
    </Form>
  );
});

export default ConnectionTable;
