import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import {  message, RenderLimitedChips } from "../../../styleguide";
import { EditFilled, EditOutlined, LeftOutlined, SearchOutlined } from "@ant-design/icons";
import DismissErrorModal from "../../common/DismissErrorModal";
import EditModal from "./EditModal";
import { renderCannotUpdateRegulationError } from "../vendor_list_v2/helper";
import { List, Map} from "immutable";
import { getSiteGroups, getAllSites } from '../../../actions/site_actions.js';
import moment from "moment";
import { Input, Tag, Button, Avatar, Modal, Switch, Table, Flex, Space, Typography, theme, Divider } from "antd";
import { useDebouncedSearch, withConfigProvider } from "../../../hooks.js";
import { activateDeactivatePreferenceConfiguration, clearPreferenceConfigurationError, getAllPreferenceConfiguration, getAllVendorConnections, getAllVendors, getPreferenceConfiguration, updatePreferenceConfiguration } from "../../../actions/preferences_actions.js";
import { getParameterByName } from "../../utils.js";
import Loading from "../../common/Loading.js.jsx";
import { browserHistory } from "react-router";
import { channelOptions } from "../helper.jsx";

const { useToken } = theme;

const { Title, Text, Paragraph } = Typography;

const EditConfigurations = (props) => {
  const [configuration, setConfiguration] = useState(null);
  const [editEntity, setEditEntity] = useState(null);
  

  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [showActivateErrorModal, setShowActivateErrorModal] = useState(false)
  const [serverErrors, setServerErrors] = useState(List([]))
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if(props.preferenceConfigurationServerSideError && typeof props.preferenceConfigurationServerSideError !== "string"){
      let content; 
      if(props.preferenceConfigurationServerSideError?.response?.status == 500){
        Modal.error({
          title: "Failed to save Configuration",
        })
        return;
      }
      const errors = props.preferenceConfigurationServerSideError.get('errors');
      const validationError = props.preferenceConfigurationServerSideError.get('err');
      if(props.preferenceConfigurationServerSideError.get('errors')?.size){
        content = <ul>{errors.map((error) => <li>{error.msg}</li>)}</ul>
      } else if(validationError){
        content = validationError
      }
      Modal.error({
        title: "Failed to save Configuration",
        content: content
      })
    }
    return ()=>{
      props.clearPreferenceConfigurationError();
    }
  },[props.preferenceConfigurationServerSideError]);

  function saveConfiguration(configuration) {
    if(configuration.propertyIds.size == 0 && configuration.isActive) {
      Modal.error({
        title: "Failed to save Configuration",
        content: "Active Configurations must have at least one property"
      })
    } else {
      props.updatePreferenceConfiguration(configuration).then((resp) => {
        if(resp?.get('id')){
          setConfiguration(resp);
          message.success(
            <>
              Configuration <b>{resp.get('name')}</b> has been updated
            </>
          );
          setEditEntity(null);
        }
      }).catch((err) => {
        message.error(err);
      })
    }
  }

  const handleDeactivate = () => {
    if( !configuration.isActive && configuration?.propertyIds.size < 1) {
      setShowActivateErrorModal(true);
    } else {
      props.activateDeactivatePreferenceConfiguration(configuration?.toJS()).then((resp) => {
        if(resp?.get('id')){
          message.success(<>Configuration <b>{configuration?.name}</b> is successfully {configuration?.isActive?'deactivated':'activated'}</>)
          setConfiguration(resp);
        }
      })
    }
    setShowDeactivateModal(false)
  }

  const configurationId = getParameterByName("configurationId");

  useEffect(() => {
    (async () => {
      if (props.currentUser) {
        props.getSiteGroups(props.currentUser);
        await props.getAllSites();
        props.getAllVendorConnections();
        props.getAllVendors();
        props.getAllPreferenceConfiguration(props.currentUser?.accountId);
        let preferenceConfiguration = await props.getPreferenceConfiguration(configurationId, props.currentUser.accountId);
        if(preferenceConfiguration.get('id')){
          setConfiguration(preferenceConfiguration);
        }
      }
    })();
  }, [props.currentUser]);

  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  const propertiesChips = configuration?.propertyIds?.map(id => <Tag>{props.sitesNameMap.get(String(id))}</Tag>);

  const channelOptionsMap = channelOptions.reduce((map, item) => {
    map[item.value] = item.label;
    return map;
  }, {});

  const columns = [
    {
      title: 'ID',
      dataIndex: 'categoryId',
      width: '12%',
      sorter: (a, b) =>  Number(a.categoryId) - Number(b.categoryId),
    },
    {
      title: 'Category',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => <Paragraph  ellipsis={{tooltip:{title: name}, rows: 3}}>{name}</Paragraph>
    },
    {
      title: 'Connections',
      dataIndex: ['vendorConnection', 'id'],
      width: '30%',
      render: (_, record) => {
        const venConTags =  record.vendorConnection.map((venConn) => {
          const vendorConnObj = props.vendorConnectionsIndexMap.get(venConn.id);
          let icon = null;
          if(vendorConnObj){  
            icon = <Avatar shape="square" style={{verticalAlign: 'sub', marginRight:'4px'}} size={18} src={<img src={props.vendorsHash?.get(vendorConnObj.vendorId)?.iconUrl} alt="vendor icon" />}/>
          }
          return (<Tag icon={icon}>{venConn?.name}</Tag>)
        })

        return <RenderLimitedChips chips={venConTags} cutOff={2}/>
      }
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      width: '30%',
      render: (channels) => {
        const labels = channels.map(value => channelOptionsMap[value]);
        const channelsTags =  labels.map(channel => {
          return (
            <Tag>{channel}</Tag>
          )
        })
        return <RenderLimitedChips chips={channelsTags} cutOff={5}/>
      }
    }
  ];

  let dataSource = searchValue.trim().length ?  configuration?.categories.filter(category => category.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1) : configuration?.categories
  dataSource = dataSource?.filter(cat => !Boolean(cat.dateDeleted));


  const { token } = useToken();
  const CustomTable = withConfigProvider(Table);

  return configuration && !props.pendingRequestsMap.get('savePending') ? (
    <>
    <div className="us-privacy-regulation-edit">
      <Flex justify="space-between">
        <Space size={token.marginXXS} >
          <Button icon={<LeftOutlined />} type="text" size="large" onClick={() => {browserHistory.push("/preferences/")}}/>
          <Title style={{marginLeft: token.marginXS, marginBottom: 0}} level={4}>{configuration.name}</Title>
          <Button type="link" icon={<EditOutlined/>} onClick={() => setEditEntity("name")}/>
        </Space>
        <Space size={token.marginSM}>
          <Switch checked={configuration.isActive} onClick={() => setShowDeactivateModal(true)}/>
          <Text>{configuration?.isActive ? "Active" : "Inactive"}</Text>
        </Space>
      </Flex>
      <div className="edit-preferences-container">
        <Space direction="vertical" size={token.marginSM}>
          <Flex justify="space-between">
            <Typography.Title level={5} style={{marginBottom: 0}}>Property ({configuration.propertyIds.size})</Typography.Title>
            <Button size={"small"}  icon={<EditOutlined/>} type="link" onClick={() => setEditEntity("properties")}/>
          </Flex>
          <Divider/>
          <RenderLimitedChips chips={propertiesChips?.toJS()} cutOff={15}/>
        </Space>
      </div>
      <div className="edit-preferences-container">
      <Space direction="vertical" size={token.marginSM}>
        <Title style={{marginBottom: 0}} level={5}>Categories and Connections</Title>
        <Flex justify="space-between">
          <Input
            placeholder="Search"
            type='search'
            onChange={({ target: { value } }) => {
              debouncedChangeHandler(value);
            }}
            suffix={<SearchOutlined style={{color:`var(--ant-color-icon)`}}/>}
            style={{width: "320px"}}
          />
          <Space size={token.marginSM}>
            <Button type="text" onClick={() => setEditEntity("category_mapping")}>Category Mapping</Button>
            <Button onClick={() => setEditEntity("add_categories")}>Manage Categories</Button>
          </Space>
        </Flex>
        <CustomTable
          columns={columns}
          dataSource={dataSource?.toJS() ?? []}
          className="preferences-category-table"
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => <div>Total Categories :{' '}{total}</div>,
            size: 'small',
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['7', '10', '15', '20'],
          }}
          componentTokens={{
            headerBorderRadius: 0
          }}
        />
      </Space>
        {/* <div className="header">
          <label htmlFor=""> Categories and Connections</label>
        </div>
        <div className="header" style={{marginBottom: "10px"}}>
          <Input
            placeholder="Search"
            type="search"
            onChange={({ target: { value } }) => {
              debouncedChangeHandler(value);
            }}
            suffix={<SearchOutlined />}
            />
          <Space>
            <Button type="text" onClick={() => setEditEntity("category_mapping")}>Category Mapping</Button>
            <Button onClick={() => setEditEntity("add_categories")}>Manage Categories</Button>
          </Space>
        </div> */}
{/*         
        <div className="content">
          <Table
            columns={columns}
            dataSource={dataSource?.toJS() ?? []}
            className="preferences-category-table"
            pagination={{
              position: ['bottomCenter'],
              showTotal: (total) => <div>Total :{' '}<b>{total}</b></div>,
              size: 'small',
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '50', '75', '100'],
              locale: { items_per_page: ' Records per page' },
            }}
          />
        </div> */}
      </div>
    </div>
    {editEntity ? (
      <EditModal
        title={null}
        modalWrapperClass="activate-confirmation"
        saveConfiguration={saveConfiguration}
        cancelEdit={() => setEditEntity(null)}
        configuration={configuration}
        edit={editEntity}
        sites={props.sites}
        siteGroups={props.siteGroups}
        preferenceConfigurationNamesList={props.preferenceConfigurationNamesList.filter( name => name != configuration.name)}
      />) : null}
    {showDeactivateModal && (<DismissErrorModal
      modalWrapperClass="operation-confirmation"
      title= {`Configuration - ${configuration?.isActive? "Deactivate":"Activate"}`}
      error={<>Are you sure you want to {configuration?.isActive? "deactivate":"activate"} the following Configuration <b>{configuration?.name}</b>&nbsp;?</>}
      isModalVisible={showDeactivateModal}
      handleCancel={() => setShowDeactivateModal(null)}
      renderOk
      okText={configuration?.isActive? "Deactivate":"Activate"}
      cancelText={"Cancel"}
      handleOk={handleDeactivate}
      primaryAction="submit"
    />)}
    {showActivateErrorModal && (<DismissErrorModal
      modalWrapperClass="activate-confirmation"
      title={`Configuration - Activate`}
      error={renderCannotUpdateRegulationError(configuration?.toJS(), serverErrors, "Configuration")}
      isModalVisible={showActivateErrorModal}
      handleCancel={() => setShowActivateErrorModal(false)}
      renderOk={false}
      okText={"OK"}
      cancelText={"Close"}
      handleOk={() => setShowActivateErrorModal(false)}
      primaryAction="submit"
    />)}
    </>
  ) : <Loading/>;
};
const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']),
    sites: store.siteState.getIn(['sites', 'value'])?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')),
    sitesNameMap: store.siteState.getIn(['sitesNameMap', 'value']),
    siteGroups: store.siteState
      .getIn(['siteGroups', 'value'])
      .sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      ),
    vendorConnectionsIndexMap: store.preferencesState.getIn(["vendorConnectionsIndexMap", "value"]),
    vendorsHash: store.preferencesState.getIn(["vendorsHash", "value"]),
    preferenceConfigurationServerSideError: store.preferencesState.getIn(["preferenceConfigurationList", "error"]),
    preferenceConfigurationNamesList: store.preferencesState.getIn(['preferenceConfigurationList', 'value'])?.map((con) => con.name) ?? List([]),
 
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      siteGroups: store.siteState.getIn(['siteGroups', 'pending']),
      vendorConnections: store.preferencesState.getIn(["vendorConnections","pending"]),
      vendors: store.preferencesState.getIn(["vendors", "pending"]),
      savePending: store.preferencesState.get('savePending')
    }),
  };
};

export default connect(mapStateToProps, {
  getSiteGroups,
  getAllSites,
  getAllVendors,
  getPreferenceConfiguration,
  getAllPreferenceConfiguration,
  getAllVendorConnections,
  updatePreferenceConfiguration,
  activateDeactivatePreferenceConfiguration,
  clearPreferenceConfigurationError
})(EditConfigurations);
