import React, { useEffect, useState } from "react";
import { Input } from "../../../styleguide";
import { List, fromJS } from "immutable";
import PropertySelection from "../../common/PropertySelection";
import IntegrationCategoryMapping from "./Screens/IntegrationCategoryMapping";
import AddCategories from "./Screens/AddCategories";
import LanguageAndTranslations from "./Screens/LanguageAndTranslations";
import {  Tabs, Alert, Modal } from "antd";
import { evaluateRequriedFieldsFromStep } from "./helper";
import classNames from "classnames";
import { useSelector } from "react-redux";

const EditModal = (props) => {
  const [configuration, setConfiguration] = useState(props.configuration);
  const [hideParentModal, setHideParentModal] = useState(false);
  const [confirmMsgBeforeSave, setConfirmMsgBeforeSave] = useState(null);
  const [stepHasError, setStepHasError] = useState(false);
  const [activeTab, setActiveTab] = useState("1")
  const configurationSavePending = useSelector(state => state.preferencesState.get('savePending'));
  const vendorConnectionsPending = useSelector(state => state.preferencesState.getIn(['vendorConnections', 'pending']))

  useEffect(()=>{
    // if(!evaluateRequriedFieldsFromStep(props.edit, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
    //   setStepHasError(true);
    // } else {
      setStepHasError(false);
    // } 
  }, [configuration]);
  
  const onChangeTab = (value) => {
    setActiveTab(value)
  }

  let content = null;
  let modalWidth = 1000;
  let modalTitle = null;
  switch(props.edit) {
    case 'name':
      const configName = configuration.get('name');
      content = (
      <>
        <label htmlFor="name"> Enter Name </label>
        <Input
          placeholder="Enter Name"
          value={configName}
          onChange={({target: {value}}) => {
            setConfiguration(configuration.set('name', value));
          }}
          // error={regulationNameError}
          maxLength={60}
          showCount={true}
          error={stepHasError ? props.preferenceConfigurationNamesList.includes(configName) ? <>Regulation with name <b>{configName}</b> already exists</> :"Please Enter a Configuration Name" : null}
        />
      </>)
      modalWidth = 400;
      break;
    case 'properties':
      content = (
        <>
        <div className="step-error">{stepHasError ? "Please select at least one property" : null}</div>
        <div className="step-section">
          <div className={`section-content ${stepHasError ? 'validation-error' : null}`}>
            <PropertySelection
              selectedPropertiesKeys={configuration.get('propertyIds')?.toJS()}
              setSelectedPropertiesKeys={(keys) => {setConfiguration(configuration.set('propertyIds', List(keys)))}}
              sites={props.sites}
              siteGroups={props.siteGroups}
            />
          </div>
        </div>
        </>)
      modalTitle = "Manage Properties";
      break;
    case 'category_mapping':
      content = (
        <IntegrationCategoryMapping
          configuration={configuration}
          updateConfiguration={setConfiguration}
          onEditScreen
        />
      );
      modalTitle = "Integration Category Mapping";
      modalWidth = 1200;
      break;
    case 'add_categories':
      content = (
        <Tabs
          activeTab={activeTab}
          onChange={onChangeTab}
        >
          <Tabs.TabPane tab="Categories" key="categories">
            <AddCategories
              configuration={configuration}
              categories={configuration?.categories}
              updateConfiguration={setConfiguration}
              onEditScreen
              setHideParentModal={(value) => {setHideParentModal(value)}}
              showError={stepHasError}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Language and Translations" key="language">
            <LanguageAndTranslations
              regulation={configuration}
              categories={configuration?.categories}
              updateConfiguration={setConfiguration}
              onEditScreen
              setHideParentModal={(value) => { setHideParentModal(value) }}
              parentTab={activeTab}
              showError={stepHasError}
            />
          </Tabs.TabPane>
        </Tabs>
      );
      modalTitle = "Manage Categories";
      modalWidth = 1200;
  }

  const handleOk = (showConfirmation) => {
    if(!evaluateRequriedFieldsFromStep(props.edit, configuration?.toJS(), props.preferenceConfigurationNamesList?.toJS())){
      setStepHasError(true);
      return;
    } 
    if(props.edit == 'properties' & showConfirmation){
      setConfirmMsgBeforeSave("These changes will affect the associated cookies and live campaigns running on associated properties. Are you sure you want to make these changes?")
    } else {
      props.saveConfiguration(configuration)
      setConfirmMsgBeforeSave(null)
    }
    setStepHasError(false);
  }

  const handleCancel = () => {
      props.cancelEdit()
  }

  return (
    <Modal
      wrapClassName={classNames("edit-preferences-modal", props.edit)}
      title={modalTitle}
      onOk={() => confirmMsgBeforeSave ? handleOk(false) : handleOk(true)}
      okText="Save"
      okButtonProps={{disabled: stepHasError || configurationSavePending, loading: configurationSavePending}}
      cancelButtonProps={{type: "link"}}
      onCancel={handleCancel}
      closable
      open={!hideParentModal}
      width={modalWidth}
      // loading={vendorConnectionsPending}
      style={{
        top: "5%"
      }}
    >
        {content}
        {confirmMsgBeforeSave ? <Alert style={{marginTop: '20px', marginBottom: '-20px'}} type={"warning"} showIcon message={confirmMsgBeforeSave}/>: null}
    </Modal>
  )
}

export default EditModal;