import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Input,
  Button,
  Alert,
} from 'antd';

import { Map } from 'immutable';
import { User } from '../../records/account_records';
import { updateUser, getUserDetails, clearUserDetailsError } from '../../actions/account_actions';
import DropdownPagesHeader from '../common/DropdownPagesHeader';
import { Tabs } from '../../styleguide';

const { TabPane } = Tabs;

export class EditAccount extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(User),
    errors: ImmutablePropTypes.listOf(PropTypes.instanceOf(Error)),
    updateUserPending: PropTypes.bool.isRequired,
    updateUser: PropTypes.func.isRequired,
    getUserDetails: PropTypes.func.isRequired,
    clearUserDetailsError: PropTypes.func.isRequired,
  }

  static defaultProps = {
    user: null,
    errors: null,
  }

  state = {
    email: '',
    name: '',
    password: '',
    passwordConfirmation: '',
    currentPassword: '',
    user: {},
  };


  static getDerivedStateFromProps(nextProps, prevState) {

    if (prevState.user !== nextProps.user) {
      return {
        email: nextProps.user.email,
        name: nextProps.user.name,
        user: nextProps.user,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updateUserPending && !this.props.updateUserPending && !this.props.errors) {
      // have to update to be able to access Devise notifications
      window.location.reload(true);
    }
  }

  handleInputChange = (e, prop) => {
    if (this.props.errors) {
      this.props.clearUserDetailsError();
    }
    let stateUpdate = new Map();
    stateUpdate = stateUpdate.set(prop, e.target.value);
    this.setState(stateUpdate.toJS());
  }

  handleUpdate = () => {
    this.props.updateUser(this.state);
  }

  render() {
    let errors;
    if (this.props.errors) {
      errors = (
        <div>
          { this.props.errors.map((err, i) => {
            return <Alert message={ err.message } type="error" key={ i } />
          })}
        </div>
      );
    }

    let accountId;
    if (this.props.user) {
      accountId = <p>Account ID: { this.props.user.accountId }</p>;
    }

    return (
      <Tabs
        type="card"
        defaultActiveKey="1"
        className="update-user-tab"
      >
        <TabPane tab="Account" key="2">
          <div>
            <div className='update-user-container'>
              { errors }
              { accountId }
              <label>Email
                <Input value={ this.state.email } disabled />
              </label>
              <label>Name
                <Input value={ this.state.name } onChange={ (e) => this.handleInputChange(e, 'name') } />
              </label>
              <label>Password (leave blank if you don't want to change it)
                <Input type="password" value={ this.state.password } onChange={ (e) => this.handleInputChange(e, 'password') } />
              </label>
              <label>Password confirmation
                <Input type="password" value={ this.state.passwordConfirmation } onChange={ (e) => this.handleInputChange(e, 'passwordConfirmation') } />
              </label>
              <label>Current password (enter to confirm changes)
                <Input type="password" value={ this.state.currentPassword } onChange={ (e) => this.handleInputChange(e, 'currentPassword') } />
              </label>
              <Button onClick={ this.handleUpdate } type="primary">Update</Button>
            </div>
          </div>
        </TabPane>
      </Tabs>
      
    );
  }
}

const mapStateToProps = function (store){
  return {
    user: store.accountState.getIn(['userDetails', 'value']),
    errors: store.accountState.getIn(['userDetails', 'error']),
    updateUserPending: store.accountState.get('updateUserPending'),
  };
};

export default connect(
  mapStateToProps, {
    updateUser,
    getUserDetails,
    clearUserDetailsError,
  },
)(EditAccount);
