import React, { useContext, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { useDebouncedSearch } from "../../../../../../hooks";
import VendorsTable from "./VendorsTable";
import { Input, Radio } from "../../../../../../styleguide";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Map } from "immutable";
import { getScannedVendorsV2 } from "../../../../../../actions/vendor_list_actions_v2";
import { generateRandomString } from "../../../../../utils";
import { usPrivacyRegulationContext } from "../../../contexts";

const AddScanResultVendors = (props) => {
  const { vendorsIdsMap} = useContext(usPrivacyRegulationContext);

  const [searchValue, setSearchValue] = useState('');
  const [vendorTableFilters, setVendorTableFilters] = useState({});
  const [vendorType, setVendorType] = useState('known')
  const [vendors, setVendors] = useState([]);

  useEffect(()=>{
    setVendors((vendorType == "unKnown" ? props.vendorWithProblems?.toJS()?.map(v => {if(!v.id) v.id = generateRandomString(10); return v;}) : props.scannedVendors?.filter(vendor => vendorsIdsMap[vendor.id])?.toJS()))
  },[vendorType, props.scannedVendors, props.vendorWithProblems])

  useEffect(() => {
    if(props.usPrivacyRegulation.siteIds.length) {
      props.getScannedVendorsV2(props.usPrivacyRegulation.siteIds, props.usPrivacyRegulation.id, props.currentUser.accountId);
    }
  }, []);

  useEffect(() => {
    if(!props.pendingRequestsMap.get('scannedVendors') && vendors.length && vendorType == "known"){
      let vendorsHashMap = props.vendorsHashMap
      vendors?.forEach(vendor => {
        vendorsHashMap[vendor.id] = props.categories.map(cat => cat.id ?? cat.privacyChoice);
      })
      props.setVendorsHashMap(vendorsHashMap);
    }
  }, [vendors]);

  const dataSource = useMemo(() => {
    if(!props.pendingRequestsMap.get('scannedVendors') && vendors.length){
      let dataSource = vendors.map(vendor => ({...vendor, vendorId: vendor.id, id: null, ids: vendorsIdsMap[vendor.id]}));
      dataSource = searchValue.trim().length ? dataSource?.filter(vendor => vendor.vendorId.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || vendor.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1) : dataSource
      return dataSource;
    }
    return [];
  },[searchValue, vendors, vendorType])

  //debouncing search for performance
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);
  const emptyTableTextCheck = vendorTableFilters.name || vendorTableFilters.categories || searchValue.trim().length;

  return props.pendingRequestsMap.get('scannedVendors') ? <Spin tip={"Loading"}/> : (
    <div>
      <div className="vendor-management-table-header-bar vendor-scan">
        <div className='vlVendorTypeBox'>
          <span>View From:</span>
          <Radio.Group onChange={(e) => setVendorType(e.target.value)} defaultValue="known" buttonStyle="solid" value={vendorType}>
            <Radio.Button value="known">Known Vendors</Radio.Button>
            <Radio.Button value="unKnown">Unknown Vendors</Radio.Button>
          </Radio.Group>
        </div>
        <Input
          placeholder="Search vendor name or ID"
          type="search"
          onChange={({ target: { value } }) => {
            debouncedChangeHandler(value);
          }}
          suffix={<SearchOutlined />}
        />
      </div>
      <div>
        <VendorsTable
          isAddSysVendorsMode
          vendorScanResults
          dataSource={dataSource}
          setSelectedVendors={props.setSelectedVendorIds}
          isLoading={props.pendingRequestsMap.get('scannedVendors')}
          categoryOptions={props.categories.map(cat => ({value: cat.id ?? cat.privacyChoice, label: cat.privacyChoice}))}
          vendorsHashMap={props.vendorsHashMap}
          updateInstantly={false}
          selectedVendors={props.selectedVendorIds}
          setVendorTableFilters={setVendorTableFilters}
          emptyTableTextCheck={emptyTableTextCheck}
          readOnly={vendorType == "unKnown"}
          overlappingVendorIds={props.isOverlap ? props.overlappingVendorIds : []}
        />
      </div>
    </div>  
  );
}

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']) ?? {},
    scannedVendors: store.vendorListState.getIn(['scannedVendors', 'value']),
    vendorWithProblems: store.vendorListState.getIn(['vendorWithProblems', 'value']),
    pendingRequestsMap: new Map({
      scannedVendors: store.vendorListState.getIn(['scannedVendors', 'pending']),
    }),
  };
};

export default connect(mapStateToProps,{
  getScannedVendorsV2
})(AddScanResultVendors);
