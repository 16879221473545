import { browserHistory } from 'react-router';
import "whatwg-fetch";
import { List } from "immutable";
import store from '../store.js';
import { addToFetchedStore } from "../actions/account_actions";
import crypto from "crypto";
import {
  GEO_FILTER_LIST_DETAILS,
  BROWSERS_LIST,
  DEVICES_LIST,
  OS_FAMILY_LIST,
  GEO_FILTER_LIST
} from "../constants";
const token = document.querySelector('meta[name="csrf-token"]');
export const CSRF_TOKEN = token && token.getAttribute("content");

export const CHANGE_LOG_URL = process.env.NODE_ENV !== "production"
    ? "https://5g9q6doyb6.execute-api.us-east-1.amazonaws.com/stage/log"
    : "https://5g9q6doyb6.execute-api.us-east-1.amazonaws.com/prod/log";

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;
const portalEnv = process.env.REACT_APP_PUB_PORTAL_ENV;

export const CHANGE_LOG_URL_V2 = `${permissionsSvcBaseUrl}/prod/log`;
// export const CHANGE_LOG_URL_V2 = `https://7xhk1lx87i.execute-api.us-east-1.amazonaws.com/${env}/log`;

export const DIAGNOSE_DASHBOARD_BASEURL = `${permissionsSvcBaseUrl}/diagnose-dashboard/${portalEnv}`;
// export const DIAGNOSE_DASHBOARD_BASEURL = `https://c8bo6r5zgb.execute-api.eu-west-2.amazonaws.com/${env}`;


export const CONSENT_PERFORMANCE_REPORT_BASEURL =
  "https://reporting-service.sp-prod.net/reports";
export const CCPA_DNSA_PERFORMANCE_REPORT_BASEURL =
  "https://reporting-service.sp-prod.net/reports";
export const GDPRV2_REPORT_BASEURL =
  "https://reporting-service.sp-prod.net/reports/tcfv2/";


export function addToFetched(url) {
  store.dispatch(addToFetchedStore(url));
}

export function isFetched(url) {
  return store
    .getState()
    .accountState.get("fetched")
    .has(url);
}

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 418 && window.location.pathname !== "/users/sign_in") {
    const signInAndRedirect = `/users/sign_in?redirect_to=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    browserHistory.push(signInAndRedirect);
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export async function checkStatusAndGetMultErrors(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 418 && window.location.pathname !== "/users/sign_in") {
    const signInAndRedirect = `/users/sign_in?redirect_to=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    browserHistory.push(signInAndRedirect);
  } else if (response.status === 400) {
    const errors = await response.json();
    throw new List(errors).map((err) => Error(err));
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export function toFormData(data) {
  const formData = new window.FormData();

  buildFormData(formData, data);

  return formData;
}

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof window.Date) &&
    !(data instanceof window.File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

export function buildUrl(url, params) {
  return url + "?" + encode(params);
}

function encode(obj, prefix) {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? encode(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
}

export function buildUrlWithVersion(url, params, apiName) {
  return `${url}?${encodeParamsWithVersion(params, '', apiName)}`;
}

export function getVersion(apiName) {
  const isPreprod = process.env.REACT_APP_PUB_PORTAL_IS_PREPROD;
  if (isPreprod) {
    return window.DIAGNOSE_API_VERSION.preprod[apiName];
  }
  return window.DIAGNOSE_API_VERSION[process.env.REACT_APP_PUB_PORTAL_ENV][apiName];
}

function encodeParamsWithVersion(obj, prefix, apiName) {
  const version = getVersion(apiName);
  var str = [`_version=${version}`],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? encode(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
}

export function buildURL(url, params) {
  return url + "?" + encodeQueryParams(params);
}

function encodeQueryParams(urlParams) {
  var s = [];
  let chk = {
    geo: GEO_FILTER_LIST_DETAILS.filter((g) => g.type == "country").size,
    device: DEVICES_LIST.size,
    browser: BROWSERS_LIST.size,
    os: OS_FAMILY_LIST.size,
  };
  for (var key in urlParams) {
    if (urlParams.hasOwnProperty(key)) {
      let flag = false;
      // to omit filters if all are selected
      if (urlParams[key] !== null && typeof urlParams[key] === "object") {
        flag =
          ["geo", "device", "browser", "os"].indexOf(key) != -1 &&
          urlParams[key].length == chk[key]
            ? true
            : Array.isArray(urlParams[key]) && urlParams[key].length == 0
            ? true
            : false;
      }
      if (!flag) {
        s.push(
          encodeURIComponent(key) +
            "=" +
            (urlParams[key] !== null && typeof urlParams[key] === "object"
              ? encodeURIComponent(urlParams[key].join(","))
              : encodeURIComponent(urlParams[key]))
        );
      }
    }
  }
  return s.join("&");
}

export function removeParam(key, sourceURL) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

export function divideByZeroErrChk(n, d) {
  return d != 0 && n > 0 ? Number(((n / d) * 100).toFixed(2)) : 0;
}

export function reportingRmReqBody(urlParams, chkFlag) {
  let formattedData = urlParams;
  let chk = {
    geo: GEO_FILTER_LIST_DETAILS.filter((g) => g.type == "country").size,
    device: DEVICES_LIST.size,
    browser: BROWSERS_LIST.size,
    os: OS_FAMILY_LIST.size,
    regions: GEO_FILTER_LIST.delete(0).size //vendor/purposes/special features regions
  };
  for (var key in urlParams) {
    if (urlParams.hasOwnProperty(key)) {
      let flag = false;
      // to omit filters if all are selected
      if (urlParams[key] !== null && typeof urlParams[key] === "object") {
        flag =
          ["geo", "device", "browser", "os"].indexOf(key) != -1 &&
          urlParams[key].length == chk[key]
            ? true
            : Array.isArray(urlParams[key]) && urlParams[key].length == 0
            ? true
            : false;
      }
      if (flag) {
        delete formattedData[key];
      }
      if (chkFlag && key == 'geo' && urlParams['geo'].length == chk['regions']) {
        delete formattedData['geo'];
      }
    }
  }
  return formattedData;
}

export const exportCSVResponse = (fileName) => (blob) => {
  // Create blob link to download
const url = window.URL.createObjectURL(new Blob([blob]));
const link = document.createElement('a');
link.href = url;
link.setAttribute('download', `${fileName}.csv`);

// Append to html link element page
document.body.appendChild(link);

// Start download
link.click();

// Clean up and remove the link
link.parentNode.removeChild(link);
};